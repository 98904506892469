<div class="main-content">
    <div class="row justify-content-end">
        <div class="title mb-0 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-4 col-12 col-lg-12 col-md-3 col-sm-3 col-xl-2 text-lg-center text-xl-center"
            *ngIf="dashboardData">
            <mat-card-title class="text-lg-center text-xl-center">Dashboard</mat-card-title>
        </div>
        <div class="col-12 col-lg-12 col-md-9 col-sm-9 col-xl-5 d-flex justify-content-end">
            <mat-form-field class="full-width" appearance="outline"
                class="col-md-4 col-4 col-sm-4 col-lg-2 col-xl-4 p-0">
                <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="fromDate" required
                    placeholder="From date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline"
                class="col-md-5 col-5 col-sm-5 col-lg-2 col-xl-4 p-lg-0 mx-lg-3">
                <input matInput [matDatepicker]="picker1" (click)="picker1.open()" required placeholder="To date"
                    [min]="fromDate" [max]="toDate" [(ngModel)]="toDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <div class="text-right p-0">


                <button class="btn btn-primary" [disabled]="loadFlag2" name="save"
                    (click)="getDashboardData()">Refresh<i *ngIf="loadFlag2"
                        class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
            </div>
        </div>
    </div>
    <div class="content col-12 mt-3" *ngIf="dashboardData && loged_user.gid != 3; else userdashboard">
        <div class="row">
            <mat-card class="mat-card  custom-card p-0">
                <mat-card-title class="mat-card-title donor text-center mb-0">At A Glance</mat-card-title>
                <div class="row">
                    <div class="text-center dashboard-label-content col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 cursor"
                        *ngIf="donation_view_permission" (click)="redirectToPage('/donations/alldonation')">
                        <div class="tab-label">Total Donations</div>
                        <div class="tab-value">{{dashboardData.donation_count}}</div>
                    </div>

                    <div class="text-center dashboard-label-content  col-lg-2 col-xl-2 col-md-4 col-sm-5 col-12"
                        *ngIf="donation_view_permission">
                        <div class="tab-label">This Month's Donations</div>
                        <div class="tab-value">{{dashboardData.monthly_donation_amount}}</div>
                    </div>

                    <div class="text-center dashboard-label-content  col-lg-2 col-xl-2 col-md-4 col-sm-3 col-12 cursor"
                        *ngIf="marriage_view_permission" (click)="redirectToPage('/services/marriage')">
                        <div class="tab-label">Marriages</div>
                        <div class="tab-value">{{dashboardData.marriage_count}}</div>
                    </div>
                    <div class="text-center dashboard-label-content  col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 cursor"
                        *ngIf="matrimonial_view_permission" (click)="redirectToPage('/services/matrimonial')">
                        <div class="tab-label">Matrimonials</div>
                        <div class="tab-value">{{dashboardData.matrimonial_count}}</div>
                    </div>

                    <div class="text-center dashboard-label-content  col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 cursor"
                        *ngIf="funeral_view_permission" (click)="redirectToPage('/services/funeral')">
                        <div class="tab-label">Funerals</div>
                        <div class="tab-value">{{dashboardData.funeral_count ? dashboardData.funeral_count : '0' }}
                        </div>
                    </div>
                    <div class="text-center dashboard-label-content  col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12">
                        <div class="tab-label">All Services</div>
                        <div class="tab-value">{{dashboardData.services_count}}</div>
                    </div>
                </div>
            </mat-card>
            <div class="col-lg-6 col-12 col-sm-8 col-xl-3 col-md-6 mt-4 p-0"
                (click)="redirectToPage('/all-events/events')" *ngIf="event_view_permission">
                <mat-card class="mat-card service mr-2 ">
                    <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100">Total Events</mat-card-title>
                    <mat-card-content class="mat-card-content d-flex">
                        <span class="text-center col-6 p-0 tile-value">{{dashboardData.events.count ? 'Total : '+
                            dashboardData.events.count
                            : 'Total : 0' }}</span>
                        <span class="text-center col-6 p-0 tile-value">{{dashboardData.events.pending ? 'Pending : '+
                            dashboardData.events.pending : 'Pending : 0' }}</span>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-12 col-sm-4 col-xl-3 col-md-6 mt-4 p-0"
                (click)="redirectToPage('/external/payment')" *ngIf="expense_view_permission">
                <mat-card class="mat-card donations mr-2 ">
                    <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100">Total Revenue
                    </mat-card-title>
                    <mat-card-content class="mat-card-content d-flex">
                        <span class="text-center col-12 tile-value">{{dashboardData.revenue_amount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-12 col-sm-4 col-xl-3 col-md-6 mt-4 p-0"
                (click)="redirectToPage('/external/expense')" *ngIf="expense_view_permission">
                <mat-card class="mat-card expenses mr-2 ">
                    <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100">Total Expenses
                    </mat-card-title>
                    <mat-card-content class="mat-card-content d-flex">
                        <span class="text-center col-12 tile-value">{{dashboardData.expense_amount?
                            dashboardData.expense_amount: '$0'
                            }}</span>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-12 col-sm-8 col-xl-3 col-md-6 mt-4 p-0"
                (click)="redirectToPage('/schools/childdetails')" *ngIf="school_view_permission">
                <mat-card class="mat-card schools mr-2 ">
                    <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100">Darul’Ilm Academy
                    </mat-card-title>
                    <mat-card-content class="mat-card-content d-flex">
                        <span class="text-center col-12 tile-value">{{dashboardData.schools_payment?
                            dashboardData.schools_payment: '$0'
                            }}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="content mt-4">
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12 col-sm-12 form-group has-search p-0">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                    [(ngModel)]="searchText" (input)="searchHits.next()" placeholder="Search" />
            </div>
            <div>
                <mat-card class="list p-0">
                    <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true"
                        [loader]="loader" [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                        (getPagination)="getPageData($event)" (getActionTable)="getActions($event)"
                        (getSorting)="sort($event)" [sqColumnDefinition]="columnDefinition" [length]="resultsLength">
                    </app-generic-table>
                </mat-card>
            </div>
        </div>
    </div>
    <ng-template #userdashboard>
        <div class="title mb-4 col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12"
            *ngIf="dashboardData && !formLoader; else showLoader">
            <div class="row">
                <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 p-0 cursor my-2 my-lg-0 my-xl-0"
                    (click)="redirectToPage('/services/marriage')" *ngIf="marriage_view_permission">
                    <mat-card class="mat-card service mr-lg-2 mr-xl-2 mr-md-2 mr-sm-2 ">
                        <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100 m-0">Marriages
                        </mat-card-title>
                        <mat-card-content class="mat-card-content text-center">
                            <span class="text-center col-6 p-0 tile-value">{{dashboardData.marriage_count ? 'Total : '+
                                dashboardData.marriage_count
                                : 'Total : 0' }}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 p-0 cursor my-2 my-lg-0 my-xl-0"
                    (click)="redirectToPage('/services/matrimonial')" *ngIf="matrimonial_view_permission">
                    <mat-card class="mat-card donations mr-lg-2 mr-xl-2">
                        <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100 m-0">Matrimonials
                        </mat-card-title>
                        <mat-card-content class="mat-card-content text-center">
                            <span class="text-center col-12 tile-value">{{dashboardData.matrimonial_count ? 'Total : ' +
                                dashboardData.matrimonial_count: 'Total: 0'}}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6  p-0 cursor my-2 my-lg-0 my-xl-0"
                    *ngIf="funeral_view_permission" (click)="redirectToPage('/services/funeral')">
                    <mat-card class="mat-card expenses mr-lg-2 mr-xl-2 mr-md-2 mr-sm-2 ">
                        <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100 m-0">Funerals
                        </mat-card-title>
                        <mat-card-content class="mat-card-content text-center">
                            <span class="text-center col-12 tile-value">{{dashboardData.funeral_count?
                                'Total : ' + dashboardData.funeral_count: 'Total: 0'
                                }}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 cursor p-0" *ngIf="school_view_permission"
                    (click)="redirectToPage('/schools/childdetails')">
                    <mat-card class="mat-card user-schools my-2 my-lg-0 my-xl-0">
                        <mat-card-title class="mat-tile text-center text-white f-s-12 f-w-100 m-0">Darul’Ilm Academy
                        </mat-card-title>
                        <mat-card-content class="mat-card-content text-center">
                            <span class="text-center col-6 p-0 tile-value">{{dashboardData.schools ? 'Total : '+
                                dashboardData.schools
                                : 'Total: 0' }}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row align-items-center">
                <mat-card class="mt-3">
                    <div class="row">
                        <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 pr-sm-1 pr-md-0 p-0 cursor mb-3 mb-lg-0 mb-xl-0"
                            *ngIf="my_event_view_permission" (click)="redirectToPage('/all-events/myevents')">
                            <mat-card class="mat-card mr-0 mr-lg-3 mr-xl-3 mr-md-3 d-flex align-items-center ">
                                <mat-icon>event</mat-icon><span class="ml-2 user-info">My Events</span>
                            </mat-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 pl-sm-1 p-0 pl-md-0 cursor mb-3 mb-lg-0 mb-xl-0"
                            *ngIf="donation_view_permission" (click)="redirectToPage('/donations/alldonation')">
                            <mat-card class="mat-card  mr-0 mr-lg-3 mr-xl-3 mr-md-0 d-flex align-items-center ">
                                <img class="mr-2" width="25" src="../../../assets/logo/donations.svg"> <span
                                    class="user-info">My Donations</span>
                            </mat-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 pr-sm-1 pr-md-0 p-0 mb-3 mb-lg-0 mb-xl-0 cursor"
                            *ngIf="mycourse_view_permission" (click)="redirectToPage('/schools/mycourses')">
                            <mat-card class="mat-card  mr-0 mr-lg-3 mr-xl-3 mr-md-3 d-flex align-items-center ">
                                <mat-icon>school</mat-icon><span class=" ml-2 user-info">My Courses</span>
                            </mat-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6 col-xl-3 col-md-6 pl-sm-1 pl-md-0 p-0 cursor"
                            *ngIf="school_view_permission" (click)="redirectToPage('/schools/childdetails')">
                            <mat-card class="mat-card d-flex align-items-center">
                                <mat-icon>supervised_user_circle</mat-icon><span class="ml-2 user-info">My Kids</span>
                            </mat-card>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="content mt-5">
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 form-group has-search p-0">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                        [(ngModel)]="searchText" (input)="searchHits.next()" placeholder="Search" />
                </div>
                <div>
                    <mat-card class="list p-0">
                        <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true"
                            [loader]="loader" [permission]="permissionsCheck"
                            [sqPaginationConfig]="tablePaginationSettings" (getPagination)="getPageData($event)"
                            (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                            [sqColumnDefinition]="columnDefinition" [length]="resultsLength">
                        </app-generic-table>
                    </mat-card>
                </div>
            </div>
        </div>
        <ng-template #showLoader>
            <div class="card mx-3" style="height: 10rem">
                <div class="text-center dashboard-loader">
                    <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                    </mat-progress-spinner>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>