import { PermissionHeadingModel } from 'src/app/data/models/PermissionHeadingModel/permission-heading.model';
import { environment } from '../../../environments/environment';

export class AppConstants {
  // service constants urls
  public static BASE_URL = environment.base_url;
  public static LOGIN_URL = '/user/login';
  public static SIGNUP_URL = '/user/create';
  public static LOGOUT_URL = '/user/logout';
  public static RESET_PASSWORD_URL = '/user/request';
  public static MARRIAGELIST_URL = '/marriage/data';
  public static MARRIAGE_URL = '/marriage';
  public static DELETE_MARRIAGE_URL = '/marriage/delete';
  public static MATRIMONIAL_LIST = '/matrimonial/data';
  public static ADDMATRINOIAL_URL = '/matrimonial';
  public static DELETEMATRINOIAL_URL = '/matrimonial/delete';
  public static FUNERALLIST_URL = '/funeral/data';
  public static ADDFUNERAL_URL = '/funeral';
  public static DELETEFUNERAL_URL = '/funeral/delete';
  public static EVENTLIST_URL = '/event/data';
  public static DELETEEVENT_URL = '/event/delete';
  public static UPCOMINGEVENT_URL = '/upcomingevent';
  public static ADDEVENT_URL = '/event';
  public static ACADEMIC_LIST_URL = '/academicyear/data';
  public static ADDACADEMIC_URL = '/academicyear';
  public static DELETEACADEMIC_URL = '/academicyear/delete';
  public static STUDENTFEE_LIST_URL = '/studentfees/data';
  public static ADDSTUDENTFEE_URL = '/studentfees';
  public static DELETESTUDENTFEE_URL = '/studentfees/delete';
  public static SCHOOL_LIST_URL = '/studentbasicdetails/data';
  public static ADDSCHOOL_URL = '/studentbasicdetails';
  public static DELETESCHOOL_URL = '/studentbasicdetails/delete';
  public static WEEKENDSCHOOL_REG_LIST_URL = '/schoolregistration/data';
  public static ADDWEEKENDSCHOOL_REG_URL = '/schoolregistration';
  public static DELETEWEEKENDSCHOOL_REG_URL = '/schoolregistration/delete';
  public static WEEKDAYSCHOOL_REG_URL = '/WeekdaySchoolRegistration/data';
  public static ADDWEEKDAYSCHOOL_REG_URL = '/WeekdaySchoolRegistration';
  public static DELETEWEEKDAYSCHOOL_REG_URL =
    '/WeekdaySchoolRegistration/delete';
  public static DONATIONS_LIST_URL = '/alldonation/data';
  public static ADDDONATIONS_URL = '/alldonation';
  public static DELETEDONATIONS_URL = '/alldonation/delete';
  public static DONATIONTYPE_LIST_URL = '/donationtype/data';
  public static ADDDONATIONTYPE_URL = '/donationtype';
  public static DELETEDONATIONTYPE_URL = '/donationtype/delete';
  public static DASHBOARD = '/dashboard';
  public static EXPENSECATEGORY_LIST_URL = '/expensecategory/data';
  public static ADDEXPENSECATEGORY_URL = '/expensecategory';
  public static DELETEEXPENSECATEGORY_URL = '/expensecategory/delete';
  public static EXPENSE_LIST_URL = '/expense/data';
  public static ADDEXPENSE_URL = '/expense';
  public static DELETEEXPENSE_URL = '/expense/delete';
  public static EXPENSEACCOUNT_LIST_URL = '/expenseaccount/data';
  public static ADDEXPENSEACCOUNT_URL = '/expenseaccount';
  public static DELETEEXPENSEACCOUNT_URL = '/expenseaccount/delete';
  public static EMPLOYEELIST_URL = '/employee/data';
  public static EMPLOYEE_URL = '/employee';
  public static MANAGE_APPROVAL_LIST_URL = '/manageapproval/data';
  public static MANAGE_APPROVAL_URL = '/manageapproval';
  public static GetAdminUsers = '/manageapproval/getAdminUsers';
  public static GROUP_MANAGEMENT_LIST_URL = '/groups/data';
  public static USER_MANAGEMENT_LIST_URL = '/users/data';
  public static PERMISSIONS_LIST_URL = '/permission';
  public static EVENT_PARTICIPANTS_URL = '/event_participant';
  public static GET_PAYMENT_METHODS = '/paymentMethod ';
  public static EXPENSECATEGORY = '/expenseCategory';
  public static EXPENSEACCOUNT = '/expenseAccount';
  public static MY_EVENTS_LIST = '/myevents';
  public static ADD_COURSE = '/courses';
  public static DISCOUNT_LIST_URL = '/coursediscount/data';
  public static DELETEDISCOUNT_URL = '/coursediscount/delete';
  public static REGISTER_COURSE = '/course_purchased';
  public static VERIFY_DISCOUNT = '/course_discount_amount';
  public static MY_COURSE_LIST = '/my_courses';
  public static ADD_DISCOUNT = '/coursediscount';
  public static SYS_DISCOUNT = '/discounts';
  public static COURSE_REGISTER_USERS = '/course_registered_users';
  public static ANONYMOUS_EVENT_REGISTER = '/eventRegistration';
  // storage constants
  public static STORAGE_KEY_USER_PERMISSIONS = 'user_permissions';
  public static permissionHeading: PermissionHeadingModel[] = [
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
    {
      heading: '',
      key: '',
      permissions: [],
      assigned: false,
    },
  ];
}
