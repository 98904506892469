<div id="discountView" class="mx-3">
    <div class="card">
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3">
            <div class="form-ajax-box">
                <ng-container *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                        <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                            <p class="col-md-12 error text-center" id="err">{{value}}</p>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="topErrorMessage">
                    <div class="col-md-12 error">
                        {{errorMsg}}
                    </div>
                </div>
                <form class="form-horizontal sximo-form validated" [formGroup]="discountForm" (ngSubmit)="onSubmit()">
                    <mat-toolbar class="main-header mb-3">
                        <mat-toolbar-row>
                            <span>{{ headings }}</span>
                            <span class="example-spacer"></span>

                        </mat-toolbar-row>
                    </mat-toolbar>
                    <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0"
                        *ngIf="!formLoader; else showLoader">
                        <div class="row">
                            <label class="form-label" for="name">Discount Information <span class="asterix"> *
                                </span></label>

                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <mat-form-field class="centered-input form-full-width">
                                    <mat-label>Discount Name</mat-label>
                                    <input matInput type="text" formControlName="name" id="name"
                                        class="centered-input form-full-width" placeholder="Discount Name"
                                        maxlength=" 30"
                                        [ngClass]="{ 'is-invalid': submitted && discountFormControl.name.errors }" />
                                </mat-form-field>
                                <div *ngIf="submitted && discountFormControl.name.errors" class="invalid-feedback">
                                    <div *ngIf="discountFormControl.name.errors?.required">Name is
                                        required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <div class="col-12">
                                    <label class="form-label" for="type">Type <span class="asterix"> *
                                        </span></label>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="centered-input form-full-width">
                                        <mat-select aria-label="Default select example" formControlName="type" id="type"
                                            (ngModelChange)="changeDiscountType($event)"
                                            [ngClass]="{ 'is-invalid': submitted && discountFormControl.type.errors }">
                                            <mat-option value="" disabled>Select</mat-option>
                                            <mat-option *ngFor="let type of discountType" [value]="type.id">
                                                {{type.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="submitted && discountFormControl.type.errors" class="invalid-feedback">
                                        <div *ngIf="discountFormControl.type.errors?.required">Type is
                                            required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <div class="col-12">
                                    <label class="form-label" for="value"> Value <span class="asterix"> *
                                        </span></label>
                                </div>
                                <div class="col-12">
                                    <mat-form-field class="centered-input">

                                        <input matInput type="number" formControlName="value" id="discountvalue"
                                            (keyup)="discountForm.value.type == 2 ? minMaxCheck(discountAmout) : ''"
                                            (keypress)="_keyPress($event)" [(ngModel)]="discountAmout"
                                            appAmountValidator step="0.01" min="{{min}}" max="{{max}}"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': submitted && discountFormControl.value.errors }" />
                                    </mat-form-field>
                                    <div *ngIf="submitted && discountFormControl.value.errors" class="invalid-feedback">
                                        <div *ngIf="discountFormControl.value.errors?.required">Value is
                                            required.
                                        </div>
                                        <div
                                            *ngIf="!discountFormControl.value.errors?.required && (!minError && !maxError) && max == '100'">
                                            Value should be 1 to 100.
                                        </div>
                                        <div *ngIf="!discountFormControl.value.errors?.required && minError">Value
                                            should
                                            not be less than
                                            $0.01.</div>
                                        <div *ngIf="!discountFormControl.value
                                      .errors?.required && maxError && max != '100'">Value should not be greater than
                                            $10000000.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 form-check p-0 mt-2 mb-2">
                                    <label class="form-label form-check-label " for="">
                                        <input type="checkbox" formControlName="hasAmountLimit"
                                            (change)="onltdAmountCheckboxChange($event.target.checked)" />
                                        Limited Amount:
                                    </label>
                                </div>
                                <div class=" row ml-4" *ngIf="showAmounts">
                                    <div class="col-6  form-group">
                                        <label class="form-label mr-1" for="minAmount">Min:<span class="asterix"> *
                                            </span></label>
                                        <mat-form-field>
                                            <input matInput type="number" formControlName="minAmount" id="minAmount"
                                                maxlength="255" min="0"
                                                [ngClass]="{ 'is-invalid': submitted && discountFormControl.minAmount.errors }" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && discountFormControl.minAmount.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.minAmount.errors?.required">min is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label class="form-label mr-1" for="ltd_amomunt_max">Max:<span class="asterix">
                                                *
                                            </span></label>
                                        <mat-form-field>
                                            <input matInput type="number" formControlName="maxAmount" id="maxAmount"
                                                maxlength="255" min="0"
                                                [ngClass]="{ 'is-invalid': submitted && discountFormControl.maxAmount.errors }" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && discountFormControl.maxAmount.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.maxAmount.errors?.required">max is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">

                                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 form-check p-0 mt-2 mb-2">
                                    <label class="form-label form-check-label " for="">

                                        <input type="checkbox" formControlName="hasQtyLimit"
                                            (change)="onltdQuntityCheckboxChange($event.target.checked)" />
                                        Limited quantity:
                                    </label>
                                </div>
                                <div class="row m-0" *ngIf="showQuentity">
                                    <div class="col-6 form-group">
                                        <label class="form-label mr-1" for="startQty">Min:<span class="asterix"> *
                                            </span></label>
                                        <mat-form-field>

                                            <input matInput type="number" formControlName="startQty" id="startQty"
                                                maxlength="255" min="0"
                                                [ngClass]="{ 'is-invalid': submitted && discountFormControl.startQty.errors }" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && discountFormControl.startQty.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="discountFormControl.startQty.errors?.required">min is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label class="form-label mr-1" for="endQty">Max:<span class="asterix"> *
                                            </span></label>
                                        <mat-form-field>

                                            <input matInput type="number" formControlName="endQty" id="endQty"
                                                maxlength="255" min="0"
                                                [ngClass]="{ 'is-invalid': submitted && discountFormControl.endQty.errors }" />
                                        </mat-form-field>
                                        <div *ngIf="submitted " class="invalid-feedback">
                                            <div *ngIf="discountFormControl.endQty.errors?.required">max is
                                                required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 mb-4">
                                    <div *ngIf="headings == 'Update Discount'" class="form-check form-switch">
                                        <input class="form-check-input" formControlName="isActive" id="isActive"
                                            name="isActive" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                                        <label class="form-check-label" for="isActive">Active</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row" style="align-items:baseline;">
                            <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex">
                                <div class="btn-group mr-2">
                                    <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i
                                            *ngIf="loadFlag2" class="fa fa-spinner fa-pulse  mt-1 ml-1"></i></button>
                                </div>
                                <div class="btn-group">
                                    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #showLoader>
                        <div class="text-center loader">
                            <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                color="accent">
                            </mat-progress-spinner>
                        </div>
                    </ng-template>
                </form>
            </div>

        </div>
    </div>
</div>