<div id="schoolregistrationView" class="mx-3">
    <div class="card">
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3">
            <div class="form-ajax-box">
                <form class="form-horizontal nsicc-form validated" [formGroup]="schoolForm" (ngSubmit)="onSubmit()"
                    *ngIf="!formLoader; else showLoader">
                    <!--Form part -->
                    <div class="controls">
                        <div class="toolbar-nav">
                            <div class="row">
                                <div class="col">
                                    <h1 class="title">{{headings}}</h1>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="topErrorMessage">
                            <div class="row err" *ngFor="let obj of keys1 ">
                                <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                                    <p class="col-md-12 error text-center" id="err">{{value}}</p>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="!topErrorMessage">
                            <div class="col-md-12 error">
                                {{errorMsg}}
                            </div>
                        </div>
                        <ng-container *ngIf="topErrorMessage">
                            <div class="row err" *ngFor="let obj of keys1 ">
                                <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                                    <p class="col-md-12 error text-center" id="err">{{value}}</p>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="!topErrorMessage">
                            <div class="col-md-12 error">
                                {{errorMsg}}
                            </div>
                        </div>
                        <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 px-0 px-xl-0 px-lg-0 px-md-0 ">
                            <h2 class="title ml-3"> Parent/Guardian info : </h2>
                            <div class="row">
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" for="parent_full_name"> Parent/Guardian full name
                                        <span class="asterix">
                                            * </span></label>
                                    <input type="text" name="parent_full_name" id="parent_full_name"
                                        (keypress)="letterOnly($event)" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && schoolFormControl.parent_full_name.errors }"
                                        class="form-control" formControlName="parent_full_name">
                                    <div *ngIf="submitted && schoolFormControl.parent_full_name.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="schoolFormControl.parent_full_name.errors.required">Parent/Guardian
                                            full
                                            name is
                                            required.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" for="relationship_with_child"> Relationship to student
                                        <span class="asterix">
                                            * </span></label>
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="relationship_with_child" id="relationship_with_child"
                                        [ngClass]="{ 'is-invalid': submitted && schoolFormControl.relationship_with_child.errors }">
                                        <option value="" selected disabled>Select</option>
                                        <option *ngFor="let relation of ['Father','Mother','Guardian']"
                                            [ngValue]="relation">{{relation}}</option>
                                    </select>
                                    <div *ngIf="submitted && schoolFormControl.relationship_with_child.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="schoolFormControl.relationship_with_child.errors.required">
                                            Relationship
                                            to student is
                                            required.
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" for="email"> Parent/Guardian email <span class="asterix">
                                            *
                                        </span></label>
                                    <input type="text" formControlName="email" class="form-control"
                                        pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                        id="email"
                                        [ngClass]="{ 'is-invalid': submitted && schoolFormControl.email.errors }" />
                                    <div *ngIf="submitted && schoolFormControl.email.errors" class="invalid-feedback">
                                        <div *ngIf="schoolFormControl.email.errors.required">Email is required.</div>
                                        <div *ngIf="schoolFormControl.email.errors.pattern">Invaild email.</div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" for="phone" class="form-label">Contact <span
                                            class="asterix">
                                            *
                                        </span></label>
                                    <div class="row m-0">
                                        <span
                                            class="input-group-addon last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2">+1</span>
                                        <input
                                            class="form-control phone col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10 "
                                            id="phone" name="phone" formControlName='phone' placeholder="(000) 000-0000"
                                            formControlName="contact_number" type="tel" appPhoneMask maxlength="14"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': submitted && schoolFormControl.contact_number.errors }"
                                            value="" />
                                        <div *ngIf="submitted && schoolFormControl.contact_number.errors"
                                            class="invalid-feedback p-0">
                                            <div *ngIf="schoolFormControl.contact_number.errors.required">Contact is
                                                required.
                                            </div>
                                            <div *ngIf="schoolFormControl.contact_number.errors.pattern">Invalid contact
                                                number.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" for="whatsapps_number"> Alternate
                                        whatsapp no <span class="asterix"> * </span></label>
                                    <div class="row m-0">
                                        <span
                                            class="input-group-addon last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2">+1</span>
                                        <input
                                            class="form-control phone col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10 "
                                            id="whatsapps_number" name="whatsapps_number"
                                            formControlName='whatsapps_number' placeholder="(000) 000-0000"
                                            formControlName="whatsapps_number" type="tel" appPhoneMask maxlength="14"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': submitted && schoolFormControl.whatsapps_number.errors }"
                                            value="" />
                                        <div *ngIf="submitted && schoolFormControl.whatsapps_number.errors"
                                            class="invalid-feedback p-0">
                                            <div *ngIf="schoolFormControl.whatsapps_number.errors.required">Whatsapp no
                                                is
                                                required.
                                            </div>
                                            <div *ngIf="schoolFormControl.whatsapps_number.errors.pattern">Invalid
                                                whatsapp
                                                no.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label" class="form-label" for="academic_year">Academic year<span
                                            class="asterix"> *
                                        </span></label>
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="academic_year" id="academic_year"
                                        (change)="getAcademicYearDetails()"
                                        [ngClass]="{ 'is-invalid': submitted && schoolFormControl.academic_year.errors }">
                                        <option value="" selected disabled>Select</option>
                                        <option *ngFor="let academicYear of academicList"
                                            [ngValue]="academicYear.academic_year_id">{{academicYear.title}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && schoolFormControl.academic_year.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="schoolFormControl.academic_year.errors.required">Academic year is
                                            required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                    <label class="form-label question_label" for="no_of_child"> How many
                                        students you want to
                                        register? <span class="asterix">
                                            * </span></label>
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="no_of_child" id="no_of_child"
                                        (change)="onChangeStudents($event)"
                                        [ngClass]="{ 'is-invalid': submitted && schoolFormControl.no_of_child.errors }">
                                        <option value="" selected disabled>Select</option>
                                        <option *ngFor="let i of [1,2,3,4,5,6,7,8,9]" value="{{i}}">{{i}}</option>
                                    </select>
                                    <div *ngIf="submitted && schoolFormControl.no_of_child.errors"
                                        class="invalid-feedback p-0">
                                        <div *ngIf="schoolFormControl.no_of_child.errors.required">No of child is
                                            required.
                                        </div>
                                    </div>

                                </div>

                                <div class="col-12 col-lg-4 col-sm-6 col-md-6 col-xl-3">
                                    <label class="form-label" class="col-12 p-0 form-label">Parent/Guardian Phone
                                        supports whats app <span class="asterix">
                                            * </span></label>
                                    <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                        <label class="form-label" class="form-label"
                                            class="text-muted form-check-label mr-5" for="whatsapps_available-0">
                                            <input type="radio" formControlName="whatsapps_available"
                                                name="whatsapps_available" value="1" class="filled-in form-check-input"
                                                id="whatsapps_available-0"
                                                [ngClass]="{ 'is-invalid': submitted && schoolFormControl.whatsapps_available.errors }"
                                                data-parsley-multiple="whatsapps_available"> <label class="form-label"
                                                class="form-label" for="whatsapps_available-0">Yes
                                            </label>
                                        </label>
                                        <label class="form-label" class="form-label" class="form-check-label"
                                            for="whatsapps_available-1">
                                            <input type="radio" formControlName="whatsapps_available"
                                                name="whatsapps_available" value="0" class="filled-in form-check-input"
                                                id="whatsapps_available-1"
                                                [ngClass]="{ 'is-invalid': submitted && schoolFormControl.whatsapps_available.errors }"
                                                data-parsley-multiple="whatsapps_available"><label class="form-label"
                                                class="form-label" for="whatsapps_available-1">No</label>
                                        </label>
                                    </div>
                                    <div *ngIf="submitted && schoolFormControl.whatsapps_available.errors"
                                        class="invalid-feedback m-0">
                                        <div *ngIf="schoolFormControl.whatsapps_available.errors.required">
                                            Parent/Guardian phone is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div *ngFor="let child of childFormControl.controls; let i = index"
                            class="list-group list-group-flush mb-4">
                            <mat-card>
                                <div class=" col-12 px-0">
                                    <h2 class="title accordion" (click)="expand(i)"
                                        [ngClass]="{'show-feedback': submitted && this.schoolForm.controls.childForm.controls[i].status === 'INVALID'}">
                                        Student Details {{i +
                                        1}} : <i class="fa"
                                            [ngClass]="{'fa-angle-up': currentIndex == i, 'fa-angle-down': currentIndex != i }"
                                            style="font-size:24px;cursor: pointer; float: right;"></i></h2>
                                    <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                                        <div [formGroup]="child" class="row"
                                            *ngIf="(currentIndex == 0 && i == 0) || (currentIndex === i && i > 0) ">
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="student_name"> Student
                                                    full
                                                    name <span class="asterix">
                                                        *
                                                    </span></label>
                                                <input type="text" name="student_name" formControlName="student_name"
                                                    maxlength="50" id="student_name" value="" required=""
                                                    class="form-control" (keypress)="letterOnly($event)"
                                                    [ngClass]="{ 'is-invalid': submitted && child.controls.student_name.errors }">
                                                <div *ngIf="submitted && child.controls.student_name.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="child.controls.student_name.errors.required">Student
                                                        full
                                                        name
                                                        is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="student_grade">Student
                                                    school grade <span class="asterix"> *
                                                    </span></label>
                                                <select class="form-select" aria-label="Default select example"
                                                    formControlName="student_grade" id="student_grade"
                                                    [ngClass]="{ 'is-invalid': submitted && child.controls.student_grade.errors }">
                                                    <option value="" selected disabled>Select</option>
                                                    <option *ngFor="let grade of gradeList" [ngValue]="grade.id">
                                                        {{grade.grade_name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && child.controls.student_grade.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="child.controls.student_grade.errors.required">Student
                                                        school grade
                                                        is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 form-group col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="student_class">
                                                    Student
                                                    class
                                                </label>
                                                <select class="form-select" aria-label="Default select example"
                                                    formControlName="student_class" id="student_class">
                                                    <option value="" selected disabled>Select</option>
                                                    <option *ngFor="let class of classList"
                                                        [ngValue]="class.student_class_id">
                                                        {{class.class_name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="dob"> Birth date <span
                                                        class="asterix"> *
                                                    </span></label>
                                                <mat-form-field class="full-width school" appearance="outline">
                                                    <input matInput [matDatepicker]="picker" (click)="picker.open()"
                                                        [ngClass]="{ 'is-invalid': submitted && child.controls.dob?.errors }"
                                                        [min]="minDate" [max]="maxDate" formControlName="dob" required
                                                        id="dob">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <mat-error *ngIf="submitted && child.controls?.dob?.errors"
                                                    class="invalid-feedback">
                                                    <mat-error class="error-message"
                                                        *ngIf="child.controls.dob?.errors?.required">
                                                        Birth
                                                        date is
                                                        required.</mat-error>
                                                </mat-error>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="Gender"> Gender <span
                                                        class="asterix"> *
                                                    </span></label>
                                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                    <label class="form-label" class="form-label" class="form-label"
                                                        class="text-muted form-check-label mr-5" for="gender-0">
                                                        <input type="radio" formControlName="gender" name="gender"
                                                            value="1" class="filled-in form-check-input" id="gender-0"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.gender.errors }"
                                                            data-parsley-multiple="gender"> <label class="form-label"
                                                            class="form-label" class="form-label" for="gender-0">Male
                                                        </label>
                                                    </label>
                                                    <label class="form-label" class="form-label"
                                                        class="form-check-label" for="gender-1">
                                                        <input type="radio" formControlName="gender" name="gender"
                                                            value="0" class="filled-in form-check-input" id="gender-1"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.gender.errors }"
                                                            data-parsley-multiple="gender"><label class="form-label"
                                                            class="form-label" class="form-label"
                                                            for="gender-1">Female</label>
                                                    </label>
                                                </div>
                                                <div *ngIf="submitted && child.controls.gender.errors"
                                                    class="invalid-feedback p-0">
                                                    <div *ngIf="child.controls.gender.errors.required">Gender is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6">
                                                <label class="form-label" class="form-label"
                                                    for="Languages/ spoken at home(choose all that apply):">
                                                    Languages/ spoken at
                                                    home(choose all that apply): </label>
                                                <div
                                                    class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 form-check d-flex pl-0">
                                                    <div *ngFor="let item of languagesPreference; let i = index"
                                                        class="mr-3">
                                                        <input type="checkbox" formControlName="{{item.value}}"
                                                            id="{{item.value}}" [value]="item.checked"
                                                            [checked]="schoolForm.value[item.value]" />
                                                        <label for="{{ item.value }}" class="form-label ml-2">{{
                                                            item.name
                                                            }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label"
                                                    for="Student can read Arabic">
                                                    Student
                                                    can read arabic
                                                    <span class="asterix"> *
                                                    </span></label>
                                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                    <label class="form-label" class="form-label" class="form-label"
                                                        class="text-muted form-check-label mr-5" for="CanReadArabic-0">
                                                        <input type="radio" formControlName="CanReadArabic"
                                                            name="CanReadArabic" value="1"
                                                            class="filled-in form-check-input" id="CanReadArabic-0"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.CanReadArabic.errors }"
                                                            data-parsley-multiple="CanReadArabic"> <label
                                                            class="form-label" class="form-label"
                                                            for="CanReadArabic-0">Yes
                                                        </label>
                                                    </label>
                                                    <label class="form-label" class="form-label"
                                                        class="form-check-label" for="CanReadArabic-1">
                                                        <input type="radio" formControlName="CanReadArabic"
                                                            name="CanReadArabic" value="0"
                                                            class="filled-in form-check-input" id="CanReadArabic-1"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.CanReadArabic.errors }"
                                                            data-parsley-multiple="CanReadArabic"><label
                                                            class="form-label" class="form-label"
                                                            for="CanReadArabic-1">No</label>
                                                    </label>
                                                </div>
                                                <div *ngIf="submitted && child.controls.CanReadArabic.errors"
                                                    class="invalid-feedback p-0">
                                                    <div *ngIf="child.controls.CanReadArabic.errors.required">Student
                                                        can
                                                        read
                                                        arabic is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label"
                                                    for="Student can write Arabic">
                                                    Student
                                                    can write
                                                    arabic
                                                    <span class="asterix"> *
                                                    </span></label>
                                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                    <label class="form-label" class="form-label" class="form-label"
                                                        class="text-muted  mr-5" for="CanWriteArabic-0">
                                                        <input type="radio" formControlName="CanWriteArabic"
                                                            name="CanWriteArabic" value="1"
                                                            class="filled-in form-check-input" id="CanWriteArabic-0"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.CanWriteArabic.errors }"
                                                            data-parsley-multiple="CanWriteArabic"> <label
                                                            class="form-label" class="form-label" class="form-label"
                                                            for="CanWriteArabic-0">Yes
                                                        </label>
                                                    </label>
                                                    <label class="form-label" class="form-label" class=""
                                                        for="CanWriteArabic-1">
                                                        <input type="radio" formControlName="CanWriteArabic"
                                                            name="CanWriteArabic" value="0"
                                                            class="filled-in form-check-input" id="CanWriteArabic-1"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.CanWriteArabic.errors }"
                                                            data-parsley-multiple="CanWriteArabic"><label
                                                            class="form-label" class="form-label" class="form-label"
                                                            for="CanWriteArabic-1">No</label>
                                                    </label>
                                                </div>
                                                <div *ngIf="submitted && child.controls.CanWriteArabic.errors"
                                                    class="invalid-feedback p-0">
                                                    <div *ngIf="child.controls.CanWriteArabic.errors.required">Student
                                                        can
                                                        write arabic is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6">
                                                <label class="form-label" class="form-label"
                                                    for="Was your child registered in any previous season of KLM Islamic School?">
                                                    Was your Student registered in any previous season of KLM islamic
                                                    school?
                                                    <span class="asterix"> *
                                                    </span></label>
                                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                    <label class="form-label" class="form-label" class="form-label"
                                                        class="text-muted form-check-label mr-5" for="IsRegisterKLM-0">
                                                        <input type="radio" formControlName="IsRegisterKLM"
                                                            name="IsRegisterKLM" value="1"
                                                            class="filled-in form-check-input" id="IsRegisterKLM-0"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.IsRegisterKLM.errors }"
                                                            data-parsley-multiple="IsRegisterKLM"> <label
                                                            class="form-label" class="form-label" class="form-label"
                                                            for="IsRegisterKLM-0">Yes
                                                        </label>
                                                    </label>
                                                    <label class="form-label" class="form-label"
                                                        class="form-check-label" for="IsRegisterKLM-1">
                                                        <input type="radio" formControlName="IsRegisterKLM"
                                                            name="IsRegisterKLM" value="0"
                                                            class="filled-in form-check-input" id="IsRegisterKLM-1"
                                                            [ngClass]="{ 'is-invalid': submitted && child.controls.IsRegisterKLM.errors }"
                                                            data-parsley-multiple="IsRegisterKLM"><label
                                                            class="form-label" class="form-label" class="form-label"
                                                            for="IsRegisterKLM-1">No</label>
                                                    </label>
                                                </div>
                                                <div *ngIf="submitted && child.controls.CanWriteArabic.errors"
                                                    class="invalid-feedback p-0">
                                                    <div *ngIf="child.controls.IsRegisterKLM.errors.required">Was your
                                                        Student
                                                        registered in any previous season of KLM islamic school is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <label class="form-label" class="form-label" for="child_memory">
                                                    Which
                                                    surahs or ajazaa
                                                    does the Student memorise </label>
                                                <input type="text" name="child_memory" id="child_memory"
                                                    formControlName="child_memory" value="" maxlength="250"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <label class="form-label" class="form-label" for="additional_info">
                                                    Additional
                                                    information, if any:
                                                </label>
                                                <input type="text" name="additional_info" maxlength="250"
                                                    id="additional_info" value="" formControlName="additional_info"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <label class="form-label" class="form-label" for="pickup_person_name">
                                                    Authorised pickup
                                                    person
                                                    name </label>
                                                <input type="text" name="pickup_person_name" id="pickup_person_name"
                                                    formControlName="pickup_person_name" maxlength="250" value=""
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                    <!-- Registration fess part -->
                    <div class="col-12 px-0 px-xl-0 px-lg-0 px-md-0 regFeesDiv" *ngIf="showRegFees">
                        <div class="text-left loader">
                            <div class="col-12">
                                <h1 class="title">Registration Fees <span class="text-warning">

                                    </span></h1>
                            </div>
                            <div class="row">
                                <!-- Registration Fees -->
                                <div class="col-md-4 mb-3">
                                    <label for="studentFee">Fees</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" class="form-control" id="studentFee" placeholder=""
                                            [value]="registerationAmount" aria-describedby="studtenFee" disabled>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">/student</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Number of Students -->
                                <div class="col-md-4 mb-3">
                                    <label for="validationCustomUsername">Number of students</label>
                                    <div class="input-group">
                                        <input type="number" disabled class="form-control" id="num_of_students"
                                            [value]="schoolForm.value.no_of_child" placeholder=""
                                            aria-describedby="inputGroupPrepend" disabled>
                                    </div>
                                </div>
                                <!-- Total Registration Fees -->
                                <div class="col-md-4 mb-3">
                                    <label for="totals">Total Registration Fees</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="">$</span>
                                        </div>
                                        <input type="text" class="form-control" id="totals" placeholder=""
                                            [value]="registerationAmount * schoolForm.value.no_of_child"
                                            aria-describedby="" disabled>

                                    </div>
                                </div>
                            </div>
                            <div class="row container center-alert">
                                <div class="alert alert-warning paymentnote" role="alert">
                                    <p style="text-align: center"> Note: This is a one time payment</p>
                                </div>
                            </div>
                            <!-- ============================================= -->
                            <!-- ===============Monthly payment============================== -->
                            <div class="row text-left">
                                <div class="col-12">
                                    <h1 class="title">Monthly Fees</h1>
                                </div>
                                <!-- Registration Fees -->
                                <div class="col-md-4 mb-3" *ngFor="let cost of studentCostArray, let index = index ">
                                    <label for="studentFee">
                                        <span *ngIf="index == 0"> First Child Fees</span>
                                        <span *ngIf="index == 1"> Second Child Fees</span>
                                        <span *ngIf="index == 2"> Third Child Fees</span>
                                        <span *ngIf="index >= 3"> {{index + 1}}th &nbsp;Child &nbsp;Fees</span>
                                    </label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" class="form-control" id="studentFeePerMonth" placeholder=""
                                            [value]="cost" aria-describedby="studentFeePerMonth" disabled>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">/month</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Number of Students -->
                                <div class="col-md-12 mb-3">
                                    <label for="studentFee">Total Fees
                                    </label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" class="form-control" id="monthlyTotal" placeholder=""
                                            [value]="calculateTotal()" aria-describedby="monthlyTotal" disabled>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">/month</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0 px-xl-0 px-lg-0 px-md-0">
                        <div class="row" style="align-items:baseline;">
                            <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex">
                                <div class="btn-group mr-2">
                                    <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i
                                            *ngIf="loadFlag2"
                                            class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                                </div>
                                <div class="btn-group">
                                    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <!-- <ng-template> -->

                <!-- </ng-template> -->
                <ng-template #showLoader>
                    <div class="text-center loader">
                        <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                        </mat-progress-spinner>
                    </div>
                </ng-template>

            </div>

        </div>
    </div>
</div>