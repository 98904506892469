<header>
    <mat-card-title class="text-lg-center text-xl-center mt-3">Payment Receipts</mat-card-title>
</header>
<div class="content">
    <div class="parent">
        <button class="btn btn-primary addButton" *ngIf="create_permission" (click)="createNew()"> Add</button>
    </div>
    <div>
        <mat-card class="list">
            <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true" [loader]="loader"
                [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                (getPagination)="getPageData($event)" (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                [sqColumnDefinition]="columnDefinition" [length]="resultsLength"></app-generic-table>
        </mat-card>
    </div>
</div>