<div class="">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">{{headings}}</h2>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" id="nsicc-modal-content">
        <div class="form-ajax-box" *ngIf="!formLoader; else showLoader">
            <form class="form-horizontal nsicc-form validated" [formGroup]="funeralPaymentForm" (ngSubmit)="onSubmit()">
                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                    <div class="row">
                        <label class="form-label centered-input">Funeral Cost: ${{ amount }} </label>
                    </div>
                    <div class="row" *ngIf="showtoAdmin">
                        <mat-form-field>
                            <mat-label>Set Custom Payment Amount </mat-label>
                            <input matInput type="number" min="0" max="1000000" formControlName="payment_amount"
                                autocomplete="off" step="1" placeholder="Change The Default Payment Amount"
                                (keyup)="minMaxCheck(amount)" (keypress)="numericOnly($event)">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <label for="Mode of payment" class="col-12 p-0 mt-2 form-label"> Mode of payment<span
                                    class="asterix">
                                    *
                                </span></label>
                            <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                <label class="form-label" class="text-muted form-check-label mr-5" for="Cash"
                                    *ngIf="showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="1"
                                        class="filled-in form-check-input" id="payment_method-0"
                                        (change)="enableChequeNumber('Cash')"
                                        [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"> <label class="form-label"
                                        for="payment_method-0">Cash
                                    </label>
                                </label>
                                <label class="form-label" class="form-check-label  mr-5" for="Cheque"
                                    *ngIf="showtoAdmin && (headings !== 'Event Register Payment' && headings !== 'Course Payment')">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="2"
                                        class="filled-in form-check-input" id="payment_method-1"
                                        (change)="enableChequeNumber('Cheque')"
                                        [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-1">Cheque</label>
                                </label>
                                <label class="form-label" class="form-check-label mr-5" for="payment_method-3"
                                    *ngIf="!showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="3"
                                        class="filled-in form-check-input" id="payment_method-3"
                                        (change)="enableChequeNumber('E-Transfer')"
                                        [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-3">E-Transfer</label>
                                </label>
                                <label class="form-label" class="form-check-label  mr-5" for="Online"
                                    *ngIf="!showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="4"
                                        class="filled-in form-check-input" id="payment_method-2"
                                        (change)="enableChequeNumber('Online')"
                                        [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-2">Online</label>
                                </label>

                                <label class="form-label" class="form-check-label" for="payment_method-4"
                                    *ngIf="showtoAdmin">
                                    <input type="radio" formControlName="payment_method" name="payment_method" value="5"
                                        class="filled-in form-check-input" id="payment_method-4"
                                        (change)="enableChequeNumber('POS')"
                                        [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.payment_method.errors }"
                                        data-parsley-multiple="payment_method"><label class="form-label"
                                        for="payment_method-4">POS</label>
                                </label>
                            </div>
                            <div *ngIf="submitted && funeralPaymentFormControl.payment_method.errors"
                                class="invalid-feedback">
                                <div *ngIf="funeralPaymentFormControl.payment_method.errors.required">
                                    Mode of payment
                                    is
                                    required.
                                </div>
                            </div>

                            <div *ngIf="funeralPaymentForm.value.payment_method == 3" class="info">
                                <p class="">To send donations through EMAIL transfer from your bank account, please
                                    use the following information:</p>
                                <address>
                                    Name: Kearney Lake Masjid<br>
                                    Email: <a href="mailto:treasurer@nsicc.ca">treasurer@nsicc.ca</a><br>
                                    Security Question: What is the donation for?<br>
                                    Security Answer: general<br>
                                </address>
                                <p>Enter the amount and purpose you want to donate and send</p>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12"
                            *ngIf="funeralPaymentForm.value.payment_method == 2 && headings !== 'Event Register Payment'">
                            <label class="form-label" for="total"> Cheque number <span class="asterix">
                                    *
                                </span></label>
                            <input type="tel" name="cheque_number" id="cheque_number" value="" class="form-control"
                                formControlName="cheque_number" (keypress)="numericOnly($event)" maxlength="15"
                                [ngClass]="{ 'is-invalid': submitted && funeralPaymentFormControl.cheque_number.errors }">
                            <div *ngIf="submitted && funeralPaymentFormControl.cheque_number.errors"
                                class="invalid-feedback">
                                <div *ngIf="funeralPaymentFormControl.cheque_number.errors.required">Cheque number is
                                    required.
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row" style="align-items:baseline;">
                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex mt-3">
                            <div class="btn-group mr-2">
                                <button class="btn btn-primary" [disabled]="loadFlag2" name="save">{{buttonText}} <i
                                        *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ng-template #showLoader>
            <div class="text-center loader">
                <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
                </mat-progress-spinner>
            </div>
        </ng-template>
    </div>
</div>