import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'app-add-system-discount',
  templateUrl: './add-system-discount.component.html',
  styleUrls: ['./add-system-discount.component.css'],
})
export class AddSystemDiscountComponent
  extends BaseComponent
  implements OnInit
{
  public showAmounts: boolean = false;
  public showQuentity: boolean = false;

  public discountForm: FormGroup;
  public discountUrl: string =
    AppConstants.BASE_URL + AppConstants.SYS_DISCOUNT;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public minDate: Date;
  public maxDate: Date;
  public discount_id: any;
  public discountType: any[] = [
    { id: 1, name: 'Percentage' },
    { id: 0, name: 'Amount' },
  ];
  public discountAmout: number;
  min: string;
  max: string;
  maxlength: number;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
  ) {
    super();
    this.service = dataManagerService;
    const currentYear = new Date().getFullYear();
    // this.minDate = new Date(
    //   currentYear - 0,
    //   new Date().getMonth(),
    //   new Date().getDate(),
    // );
    // this.maxDate = new Date(currentYear + 10, 11, 31);
    this.getFormData();
  }

  ngOnInit(): void {
    this.setdiscountForm();
  }
  setdiscountForm() {
    this.discountForm = this.formBuilder.group({
      name: [
        this.data && this.data.name ? this.data.name : '',
        [Validators.required],
      ],
      // code: [
      //   this.data && this.data.code ? this.data.code : '',
      //   [Validators.required],
      // ],
      type: [
        this.data && this.data.type ? this.data.type : 0,
        [Validators.required],
      ],
      value: [
        this.data && this.data.value ? this.data.value : 0,
        [Validators.required],
      ],
      minAmount: [this.data && this.data.minAmount ? this.data.minAmount : ''],
      maxAmount: [this.data && this.data.maxAmount ? this.data.maxAmount : ''],
      startQty: [this.data && this.data.startQty ? this.data.startQty : ''],
      endQty: [this.data && this.data.endQty ? this.data.endQty : ''],
      hasAmountLimit: [
        this.data && this.data.hasAmountLimit
          ? this.data.hasAmountLimit
          : false,
      ],
      hasQtyLimit: [
        this.data && this.data.hasQtyLimit ? this.data.hasQtyLimit : false,
      ],
      isActive: this.data && this.data.isActive ? this.data.isActive : false,
    });
  }
  get discountFormControl() {
    return this.discountForm.controls;
  }
  onltdAmountCheckboxChange(checked: boolean) {
    this.showAmounts = checked;
    if (this.showAmounts) {
      this.discountForm.controls['minAmount'].setValidators([
        Validators.required,
      ]);
      this.discountForm.controls['minAmount'].updateValueAndValidity();
      this.discountForm.controls['maxAmount'].setValidators([
        Validators.required,
      ]);
      this.discountForm.controls['maxAmount'].updateValueAndValidity();
    } else {
      this.discountForm.controls['minAmount'].clearValidators();
      this.discountForm.controls['maxAmount'].clearValidators();
    }
  }

  onltdQuntityCheckboxChange(checked: boolean) {
    this.showQuentity = checked;
    if (this.showQuentity) {
      this.discountForm.controls['startQty'].setValidators([
        Validators.required,
      ]);
      this.discountForm.controls['startQty'].updateValueAndValidity();
      this.discountForm.controls['endQty'].setValidators([Validators.required]);
      this.discountForm.controls['endQty'].updateValueAndValidity();
    } else {
      this.discountForm.controls['startQty'].clearValidators();
      this.discountForm.controls['endQty'].clearValidators();
    }
  }
  onSubmit() {
    this.submitted = true;

    if (this.discountForm.invalid) {
      return;
    } else {
      if (this.data) {
        this.discountForm.value.entry_by = this.loged_user.gid;
        this.discountForm.value.id = this.data.id;
      }
      this.loadFlag2 = true;

      if (this.buttonText == 'Save') {
        this.discountForm.value.isActive = true;
        this.service
          .create(this.discountUrl, this.discountForm.value)
          .subscribe({
            next: (res: any) => {
              console.log(res);
              if (res['status'] == 'success') {
                this.loadFlag2 = false;
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router.navigate(['/all-discount/discount']);
              } else {
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = [
                  'name',
                  'type',
                  'value',
                  'hasAmountLimit',
                  'maxAmount',
                  'minAmount',
                  'hasQtyLimit',
                  'startQty',
                  'endQty',
                ];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            },
            error: (error) => {
              this.loadFlag2 = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      } else if ((this.buttonText = 'Update')) {
        this.loadFlag2 = true;

        this.service
          .create(
            this.discountUrl + '/' + this.discount_id,
            this.discountForm.value,
          )
          .subscribe({
            next: (res: any) => {
              console.log(res);
              if (res['status'] == 'success') {
                this.loadFlag2 = false;
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router.navigate(['/all-discount/discount']);
              } else {
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = [
                  'name',
                  'type',
                  'value',
                  'hasAmountLimit',
                  'maxAmount',
                  'minAmount',
                  'hasQtyLimit',
                  'startQty',
                  'endQty',
                ];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            },
            error: (error) => {
              this.loadFlag2 = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      }
    }
  }
  cancel() {
    this.router.navigate(['/all-discount/discount']);
  }

  getFormData() {
    this.formLoader = true;
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.service.getById(this.discountUrl + '/' + params['id']).subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              if (res.data) {
                this.data = res.data;
                this.discountAmout = Number(this.data.value);
                this.discount_id = this.data.id;
              }
              this.showQuentity = this.data.hasQtyLimit;
              this.showAmounts = this.data.hasAmountLimit;
              this.setdiscountForm();
              this.changeDiscountType(this.data.type);
              this.headings = 'Update Discount';
              this.buttonText = 'Update';
              this.formLoader = false;
            } else {
              this.formLoader = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.formLoader = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
      } else {
        this.formLoader = false;
        this.headings = 'Create Discount';
        this.buttonText = 'Save';
      }
    });
  }
  // updateCase() {
  //   this.discountForm.value.code = this.discountForm.value.code.toUpperCase();
  // }
  changeDiscountType(type: any) {
    if (type == 1) {
      this.min = '1';
      this.max = '100';
      this.maxlength = 3;
    } else {
      this.min = '0.01';
      this.max = '9999999.99';
      this.maxlength = 10;
    }
  }
}
