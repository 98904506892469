<div class="login-logo">
</div>
<div class="container">

    <div class="card">
        <mat-toolbar class="main-header">
            <mat-toolbar-row>
                <span>Unsubscribe Payment</span>
                <span class="example-spacer"></span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body">
            <div class="form-ajax-box">
                <div class="inner-container">
                    <div class="letter-img">
                        <img src="./assets/cancel.svg" class="letter-img">
                    </div>
                    <div class="bottom" *ngIf="status==true">
                        <h2 class="title">Do you really want to unsubscribe?</h2>
                        <p class="subtitle">
                            We kindly ask you to reconsider cancelling your recurring payment. Your support is crucial
                            for our mission, and it enables us to make a meaningful impact. Thank you for your ongoing
                            generosity; it truly makes a difference.</p>
                        <div class="buttons">
                            <button id="unsubscribe" (click)="cancelPayment()"
                                class="btn btn-warning px-4">Unsubscribe</button>
                            <button id="cancel" (click)="backtoHome()" class="btn btn-light px-4">Keep me
                                subscribed</button>
                        </div>
                    </div>
                    <div class="bottom" *ngIf="status==false">
                        <h2 class="title">Payment is already closed or not found</h2>

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="login-logo">
</div>