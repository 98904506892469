import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from './base.component';
import { Subject } from 'rxjs';
import {
  ColumnSettingsModel,
  TablePaginationSettingsModel,
} from '../constants/table-settings.model';

export abstract class BaseListComponent extends BaseComponent {
  public dataManager: DataManagerService;
  protected snackBar: MatSnackBar;
  public rows: number;
  public page: number;
  protected getListUrl: string;
  public searchHits: Subject<any>;
  public searchText: string;
  public localSearch = false;
  protected searchFields: string[] = [];
  orderBy: string;
  sortBy: string;
  public loader: boolean = false;
  public filterUrl: string;
  public resultsLength: number;
  public columnDefinition: ColumnSettingsModel[] = [];
  public rowData: any[] = [];
  public tablePaginationSettings: TablePaginationSettingsModel = <
    TablePaginationSettingsModel
  >{};
  public reportFromDate: any;
  public reporttoDate: any;
  protected getList(url: string) {
    console.log('url', url);
    this.service.getAllList(url).subscribe(
      (res) => {
        this.getListRes(res);
      },
      (err) => {
        this.getListErr(err);
      },
    );
  }
  //
  public setUrl() {
    let url = this.getListUrl;
    url =
      url +
      (this.rows && this.page
        ? '?rows=' + this.rows + '&page=' + this.page
        : '?rows=10' + '&page=1') +
      (this.isNullOrUndefined(this.searchText) || this.localSearch
        ? ''
        : '&s=' + this.searchTextFormat()) +
      (this.isNullOrUndefined(this.orderBy)
        ? ''
        : '&orderBy=' + this.orderBy + '&sortedBy=' + this.sortBy) +
      (this.isNullOrUndefined(this.filterUrl)
        ? ''
        : '&search=' + this.filterUrl) +
      (this.isNullOrUndefined(this.reportFromDate && this.reporttoDate)
        ? ''
        : '&fromDate=' + this.reportFromDate + '&toDate=' + this.reporttoDate);

    this.getList(url);
    console.log(url);
    return url;
  }
  private searchTextFormat(): string {
    let text = '';
    if (this.searchFields.length === 0) {
      return this.searchText;
    } else {
      this.searchFields.forEach((field) => {
        text += field + ':' + this.searchText + ';';
      });
    }
    return text.substring(0, text.length - 1);
  }

  protected abstract getListRes(res: any): any;

  protected abstract getListErr(err: any): any;

  sort(data: any) {
    if (!this.isNullOrUndefined(data.active)) {
      if (data.getNextSortDirection(data.active)) {
        this.sortBy = data.getNextSortDirection(data.active);
        this.orderBy = data.active;
        this.callSetUrl();
      } else {
        this.sortBy =
          data.getNextSortDirection(data.active) != ''
            ? data.getNextSortDirection(data.active)
            : 'asc';
        this.orderBy = data.active;
        this.callSetUrl();
      }
    } else {
    }
  }
  callSetUrl() {
    this.setUrl();
  }
  getPageData(event: any) {
    this.rows = event.pageSize;
    if (event.pageIndex === 0 && event.previousPageIndex == 0) {
      this.page = 1;
    } else if (event.pageIndex > event.previousPageIndex) {
      this.page = event.pageIndex + 1;
    } else if (event.pageIndex < event.previousPageIndex) {
      this.page = event.previousPageIndex;
    }
    this.page = this.page == 0 ? 1 : this.page;

    this.setUrl();
  }
  getTableSettings() {
    this.tablePaginationSettings.enablePagination = true;
    this.tablePaginationSettings.pageSize = 10;
    this.tablePaginationSettings.pageSizeOptions = [5, 10, 20, 50];
    this.tablePaginationSettings.showFirstLastButtons = true;
  }
}
