<div class="">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
            aria-hidden="true">×</span></button>
    
</div>
<div class="modal-content">
    <mat-card class="view-page mat-card mat-focus-indicator p-0">
        <div class="form-field-full">
            <div class="card">
                <div class="card-body">
                    <div class="col p-0">
                        <h1 class="title">View Purpose Of Donation</h1>
                    </div>
                    <div class="col-12 p-0 view-content table-responsive">
                        <table class="mb-0 table  table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%" class="label-view text-right">Purpose of donation</td>
                                    <td>{{typename | titlecase}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

</div>