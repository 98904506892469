<div class="content">
    <div class="row justify-content-space-between">
        <div class="col-lg-4 col-xl-4 col-md-8 col-sm-10 col-9 form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                [(ngModel)]="searchText" (input)="searchHits.next()" placeholder="Search" />
        </div>
        <div class="col-lg-8 col-xl-8 col-md-4 col-3 col-sm-2  pl-0 text-right" *ngIf="permissionsCheck.excel_permission">
            <button class="btn btn-primary" (click)="exportexcel()">Export <i *ngIf="loadFlag2"
                    class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
        </div>
    </div>
    <div>
        <mat-card class="list">
            <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true" [loader]="loader"
                [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                (getPagination)="getPageData($event)" (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                [sqColumnDefinition]="columnDefinition" [length]="resultsLength"></app-generic-table>
        </mat-card>
    </div>
</div>
