import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from '../abstract/base.component';
import { AppConstants } from '../constants/app-constants';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent extends BaseComponent implements OnInit {
  public checkoutForm: FormGroup;
  public paymentUrl: string;
  public checkOutUrl: string;
  public redirectUrl: string;
  public urlParam: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    service: DataManagerService,
    private fb: FormBuilder,
  ) {
    super();
    this.service = service;
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.urlParam = params;
      }
    });
    const current_path = window.location.pathname;
    if (current_path.includes('schools') && !this.urlParam.type) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADD_COURSE +
        '/coursePaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl = AppConstants.BASE_URL + '/course/coursePayment/';
      this.redirectUrl = '/schools/mycourses';
    } else if (current_path.includes('services')) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDFUNERAL_URL +
        '/funeralPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDFUNERAL_URL +
        '/funeralPayment/';
      this.redirectUrl = '/services/funeral';
    } else if (current_path.includes('all-events')) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDEVENT_URL +
        '/eventPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL + AppConstants.ADDEVENT_URL + '/eventPayment/';
      this.redirectUrl = '/all-events/myevents';
    } else if (current_path.includes('donations')) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDDONATIONS_URL +
        '/donationPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDDONATIONS_URL +
        '/donationPayment/';
      this.redirectUrl = '/donations/alldonation';
    } else if (
      current_path.includes('schools') &&
      this.urlParam.type == 'weekend'
    ) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDWEEKENDSCHOOL_REG_URL +
        '/weekendSchoolPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDWEEKENDSCHOOL_REG_URL +
        '/weekendSchoolPayment/';
      this.redirectUrl = '/schools/weekendschoolregistration';
    } else if (
      current_path.includes('schools') &&
      this.urlParam.type == 'weekday'
    ) {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDWEEKDAYSCHOOL_REG_URL +
        '/weekdaySchoolPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADDWEEKDAYSCHOOL_REG_URL +
        '/weekdaySchoolPayment/';
      this.redirectUrl = '/schools/weekdayschoolregistration';
    } else if (this.urlParam.type == 'anonymous-donation') {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        '/anonymous-donation/anonymousDonationPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL + '/anonymous-donation/anonymousPayment/';
      this.redirectUrl = '/login';
    } else if (this.urlParam.type == 'anonymous-event-register') {
      this.checkOutUrl =
        AppConstants.BASE_URL +
        '/anonymous_event_participant/anonymousEventPaymentCheckout/' +
        this.urlParam.id;
      this.paymentUrl =
        AppConstants.BASE_URL +
        '/anonymous_event_participant/anonymousEventPayment/';
      this.redirectUrl = '/login';
    }
    this.buttonText = ' Proceed for Payment';
  }

  ngOnInit(): void {
    this.checkoutForm = this.fb.group({
      terms_condition: [false, [Validators.requiredTrue]],
    });
    this.formLoader = true;
    var payload: any = {
      payment_method: 4,
    };
    this.route.queryParams.subscribe((params) => {
      if (params['school_id']) {
        payload['school_id'] = params['school_id'];
      }
    });
    this.service.create(this.checkOutUrl, payload).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.formLoader = false;
          this.data = res.data;
          this.data.amount = Number(this.data.amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          if (this.isNullOrUndefined(this.data.discount_amount)) {
            this.data.discount_amount = 0;
          }
          if (this.isNullOrUndefined(this.data.amount_after_discount)) {
            this.data.amount_after_discount = 0;
          }

          this.data.discount_amount = Number(
            this.data.discount_amount,
          ).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          this.data.amount_after_discount = Number(
            this.data.amount_after_discount,
          ).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          console.log(this.data);
        } else {
          this.formLoader = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  get checkoutFormControl() {
    return this.checkoutForm.controls;
  }

  cancel() {
    if (this.paymentUrl.includes('coursePayment')) {
      this.router.navigate(['/schools/courses'], { relativeTo: this.route });
    } else if (this.urlParam.type == 'anonymous-donation') {
      this.router.navigate(['/donation']);
    } else if (this.urlParam.type == 'anonymous-event-register') {
      this.router.navigate([
        '/anonymous-event-register/' +
          this.urlParam.event_id +
          '/' +
          this.urlParam.event_type,
      ]);
    } else {
      this.router.navigate([this.redirectUrl], { relativeTo: this.route });
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.checkoutForm.invalid) {
      return;
    } else {
      this.loadFlag2 = true;
      let payload: any = {
        payment_method: 4,
        return_url: window.location.origin + this.redirectUrl,
      };
      if (this.urlParam.school_id) {
        payload['school_id'] = this.urlParam['school_id'];
      }
      this.service
        .create(this.paymentUrl + this.data.payable_id, payload)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              window.open(res.data, '_blank');
              this.router.navigate([this.redirectUrl]);
            } else {
              this.loadFlag2 = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
}
