<table cellpadding="0" cellspacing="0" cols="1" align="center" style="max-width: 600px;">
    <tr>
        <td height="10"
            style=" font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
        </td>
    </tr>

    <!-- This encapsulation is required to ensure correct rendering on Windows 10 Mail app. -->
    <tr>
        <td
            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
            <!-- Seperator Start -->
            <table cellpadding="0" cellspacing="0" cols="1" align="center" style="max-width: 600px; width: 100%;">
                <tr>
                    <td height="10"
                        style=" font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
            </table>
            <!-- Seperator End -->

            <!-- Generic Pod Left Aligned with Price breakdown Start -->
            <table align="center" cellpadding="0" cellspacing="0" cols="3" bgcolor="white" class="bordered-left-right"
                style="border: 1px solid #d7d7d7; max-width: 600px; width: 100%;">
                <tr height="50">
                    <td colspan="3"
                        style=" font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr align="center">
                    <td width="36"
                        style=" font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td class="text-primary"
                        style="color: #F16522; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <img [src]=" imageSrc " alt="GO" width="50"
                            style="border: 0; font-size: 0; margin: 0; max-width: 100%; padding: 0;">
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr height="17">
                    <td colspan="3"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr align="center">
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td class="text-primary"
                        style="color: #F16522; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <h1
                            style="color: #F16522; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 30px; font-weight: 700; line-height: 34px; margin-bottom: 0; margin-top: 0;">
                            Payment {{ paymentStatus }}</h1>
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <h5> </h5>

                <tr align="center" *ngIf="transactionStatus == 'Denied'">
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td class="text-primary"
                        style="color: #F16522; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <p
                            style="color: red; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; line-height: 34px; margin-bottom: 0; margin-top: 0;">
                            {{ response?.messageText }}</p>
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr height="30">
                    <td colspan="3"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr align="left">
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                            Hi {{ data?.payable_name }}, <br>

                        </p>
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr height="10">
                    <td colspan="3"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr align="left">
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                            Your transaction was {{ transactionStatus }}!</p>
                        <br>
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0; ">
                            <strong>Payment Details:</strong><br />

                            Amount: ${{ data?.amount_after_discount }} <br />
                            {{ isNullOrUndefined(this.response?.cardBin) ? '' : 'Card Number:' + accountNumber
                            }}<br />
                            {{ isNullOrUndefined(response?.trnOrderNumber) || response?.trnOrderNumber === "" ? '' :
                            'Transaction Number:' +
                            response?.trnOrderNumber }}
                        </p>
                        <br>
                        <div *ngIf=" ! isNullOrUndefined(recurring)">
                            <p
                                style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                                <strong> Recurring Details:</strong> <br />

                                Recurring Frequency: {{ getRecurringType(recurring?.type) }} <br>
                                Recurring Count: {{ recurring?.recurring_count }} <br>
                                Start Date: {{ recurring?.recurring_date }}<br>
                                End Date: {{ recurring?.end_date }} <br>

                            </p>
                        </div>
                        <br>
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                            We advise to keep this receipt for future reference.&nbsp;&nbsp;&nbsp;&nbsp;<br /></p>
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr height="30">
                    <td
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td
                        style="border-bottom: 1px solid #D3D1D1; color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr height="30">
                    <td colspan="3"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>
                <tr align="center">
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                    <td
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">

                            <strong>Transaction reference: {{ isNullOrUndefined(response?.rbAccountId) ||
                                response?.rbAccountId === "" ?'Declined' : response?.rbAccountId }}
                            </strong>
                        </p>
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                            Transaction date: {{ response?.trnDate }}</p>
                        <p
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 22px; margin: 0;">
                        </p>
                    </td>
                    <td width="36"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>

                <tr height="50">
                    <td colspan="3"
                        style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                    </td>
                </tr>

            </table>
            <!-- Generic Pod Left Aligned with Price breakdown End -->

            <!-- Seperator Start -->
            <table cellpadding="0" cellspacing="0" cols="1" align="center"
                style="max-width: 600px; height: 70px; margin-top:-15px; width: 100%;">
                <tr>
                    <td height="50" align="center"
                        style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        <br>
                        <button *ngIf="showPrint" class="btn btn-primary d-block w-100" id="printButton"
                            (click)="printPage($event)">Print</button>

                    </td>
                </tr>
            </table>
            <!-- Seperator End -->

        </td>
    </tr>
</table>