import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseListComponent } from 'src/app/shared/abstract/base-list.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { HeaderConstants } from 'src/app/shared/constants/header-constants';
import { PermissionConstants } from 'src/app/shared/constants/permission-constants';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  preserveWhitespaces: true,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class DashboardComponent
  extends BaseListComponent
  implements OnInit, OnDestroy
{
  public dashboard_url = AppConstants.BASE_URL + AppConstants.DASHBOARD;
  currentDate: Date = new Date();
  cellDuration = 30;
  public month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public fromDate: Date;
  public toDate: Date;
  public dashboardData: any;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public donationTypeLookup: { [key: number]: string } = {};
  school_view_permission: boolean;
  event_view_permission: boolean;
  expense_view_permission: boolean;
  funeral_view_permission: boolean;
  marriage_view_permission: boolean;
  matrimonial_view_permission: boolean;
  my_event_view_permission: boolean;
  donation_view_permission: boolean;
  course_view_permission: boolean;
  mycourse_view_permission: boolean;

  constructor(
    services: DataManagerService,
    private datePipe: DatePipe,
    private router: Router,
  ) {
    super();
    this.loader = true;
    this.service = services;
    this.fromDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1,
    );
    this.toDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    );
    this.getListUrl = AppConstants.BASE_URL + AppConstants.DONATIONS_LIST_URL;
    this.columnDefinition = HeaderConstants.AllDonationsListHeader;
    this.columnDefinition.pop();
    this.searchHits = new Subject<void>();
    this.getTableSettings();
    this.school_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_STUDENTBASICDETAILS_VIEW,
    );
    this.funeral_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNERAL_VIEW,
    );
    this.donation_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_VIEW,
    );
    this.course_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_COURSE_VIEW,
    );
    this.mycourse_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_MY_COURSE_VIEW,
    );
    this.marriage_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_MARRIAGE_VIEW,
    );
    this.matrimonial_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_MATRIMONIAL_VIEW,
    );
    this.school_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_STUDENTBASICDETAILS_VIEW,
    );
    this.event_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EVENT_VIEW,
    );
    this.my_event_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_MYEVENTS_VIEW,
    );
    this.expense_view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_EXPENSE_VIEW,
    );
    this.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_ADD,
    );
    this.delete_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_REMOVE,
    );
    this.view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_VIEW,
    );
    this.edit_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_DONATION_EDIT,
    );
    this.permissionsCheck = {
      edit_permission: this.edit_permission,
      delete_permission: this.delete_permission,
      view_permission: this.view_permission,
      create_permission: this.create_permission,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.getDonationType();
    this.dashboard();
  }

  protected getListRes(res: any): void {
    this.rowData = [];
    if (res['status'] == 'success') {
      this.resultsLength = res.data.pagination.total;
      res.data.rowData.forEach((element: any, index: number) => {
        element.donor_fullname = element.fname + ' ' + element.lname;

        for (let key in element) {
          if (key == 'payment_method') {
            element[key] =
              element[key] == '1' || element[key] == 'Cash'
                ? 'Cash'
                : element[key] == '2' || element[key] == 'Cheque'
                ? 'Cheque'
                : element[key] == '3' || element[key] == 'E-Transfer'
                ? 'E-Transfer'
                : element[key] == '4' || element[key] == 'Online'
                ? 'Online'
                : 'POS';
          }
          if (key == 'donation_amount') {
            element[key] = element[key].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          }
          if (key == 'donation_type') {
            if (element[key] in this.donationTypeLookup) {
              element[key] = this.donationTypeLookup[element[key]];
            } else {
              element[key] = '-';
            }
          }

          if (key == 'recurring_count') {
            if (element.recurring_count === null) {
              element.recurring_count = 'One Time';
            } else {
              element.recurring_count = this.generateRecurringSchedule(
                element.recurring_type,
                element.recurring_count,
              );
            }
          }
          if (key == 'end_date') {
            if (element.end_date == null) {
              element.end_date = '--';
            }
          }
          if (key == 'payment_sts') {
            if (!this.isNullOrUndefined(element.recurring_status)) {
              element.donation_Payment_sts =
                element.recurring_status == 1 ? 'Active' : 'Closed';
            } else {
              element.donation_Payment_sts =
                element[key] == null || element[key] == 'Pending'
                  ? 'Pending'
                  : element[key] == 1 || element[key] == 'Completed'
                  ? 'Completed'
                  : 'Verify Payment';
            }
          }
          if (key == 'payment_date' || key == 'receipt_no') {
            element[key] =
              element[key] == null || element[key] == '' ? '--' : element[key];
          }
        }
        this.rowData.push(element);
      });
      this.loader = false;
    }
  }

  protected getListErr(err: any): void {
    console.log(err);
  }
  redirectToPage(data: string) {
    if (data.includes('external')) {
      this.router
        .navigateByUrl('home', { skipLocationChange: true })
        .then(() => {
          this.router.navigate([data]);
        });
    } else {
      this.router.navigate([data]);
    }
  }
  getDonationType() {
    const getListUrl = AppConstants.BASE_URL + '/donation_type';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.dontationTypeList = res.data;
          if (this.dontationTypeList) {
            for (let donationType of this.dontationTypeList) {
              this.donationTypeLookup[donationType.donation_type_id] =
                donationType.type_name;
            }
            this.loader = true;
            this.setUrl();
          }
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  getDashboardData() {
    this.loadFlag2 = true;
    this.dashboard();
  }
  dashboard() {
    this.formLoader = true;
    this.service
      .getById(
        this.dashboard_url +
          '?fromDate=' +
          this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') +
          '&toDate=' +
          this.datePipe.transform(this.toDate, 'yyyy-MM-dd'),
      )
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.formLoader = false;
            this.loadFlag2 = false;
            this.dashboardData = res.data;
            for (let data in this.dashboardData) {
              if (
                data &&
                (data.includes('amount') || data.includes('schools_payment'))
              ) {
                this.dashboardData[data] =
                  this.dashboardData[data] != null
                    ? Number(this.dashboardData[data]).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })
                    : '';
              }
            }
          } else {
            this.formLoader = false;
            this.loadFlag2 = false;
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.formLoader = false;
          this.loadFlag2 = false;
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
  }
  ngOnDestroy(): void {
    let object = {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    };
    HeaderConstants.AllDonationsListHeader.push(object);
  }
  getRecurringType(recurring_count: any) {
    switch (recurring_count) {
      case 2:
        return 'Days';
      case 3:
        return 'Weeks';
      case 4:
        return 'Months';
      case 5:
        return 'Years';
      default:
        return '';
    }
  }
  generateRecurringSchedule(type: any, count: any) {
    let schedule = count + ' ' + this.getRecurringType(type);
    return schedule;
  }
}
