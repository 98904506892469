<div class="">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
            aria-hidden="true">×</span></button>

</div>
<div class="modal-content">
    <div class="container">
        <mat-card class="view-page mat-card mat-focus-indicator p-0">
            <div class="card">
                <div class="card-body">
                    <div class="form-field-full">
                        <div class="col p-0">
                            <h1 class="title">Class Details </h1>
                        </div>
                        <div class="col-12 p-0 view-content table-responsive">
                            <table class="mb-0 table  table-bordered">
                                <tbody>
                                    <tr>
                                        <td class="label-view text-left col-3">Class Name</td>
                                        <td class="col-3">{{data.class_name | titlecase}}</td>
                                    </tr>
                                    <tr>
                                        <td class="label-view text-left col-3">School Type</td>
                                        <td class="col-3">{{data.school_type}}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </mat-card>

    </div>