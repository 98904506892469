import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable()
export class DataManagerService {
  public loggedInStatus = JSON.parse(
    localStorage.getItem('loggedIn') || 'false',
  );
  public status = new EventEmitter();
  protected httpFileUploadHeaders: HttpHeaders;
  public permissionsArray: [] = [];
  public menuList: [] = [];
  protected httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    this.status.emit(this.loggedInStatus);
    this.httpFileUploadHeaders = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data',
    );
    this.httpFileUploadHeaders = new HttpHeaders().set(
      'Authorization',
      JSON.parse(localStorage.getItem('authToken') || 'null')
        ? 'Bearer ' + JSON.parse(localStorage.getItem('authToken') || 'null')
        : '',
    );
    this.httpFileUploadHeaders = this.httpFileUploadHeaders.append(
      'Content-Type',
      'application/json',
    );
  }
  userLogin(url: string, payload: Object) {
    return this.http.post(url, payload);
  }

  /**
   * func to hanlde toast messages  on
   * sncakBar to show error message or action
   * @param message message to show
   * @param action Suggested Acttion Result
   * @param duration time duration the message should be shown
   */
  showSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  userSignup(url: string, payload: Object) {
    return this.http.post(url, payload);
  }

  logout(url: string) {
    return this.http.post(url, '');
  }

  setLoginStatus(value: any) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', 'true');
  }

  LoginStatus() {
    this.status.emit(this.loggedInStatus);
    return JSON.parse(
      localStorage.getItem('loggedIn') || this.loggedInStatus.toString(),
    );
  }
  resetPassword(url: string, payload: Object) {
    return this.http.post(url, payload);
  }

  getAllList(url: any) {
    return this.http.get(url);
  }

  getAllDiscounts(url: string, payload: Object) {
    return this.http.post(url, payload);
  }
  create(url: string, payload: Object) {
    return this.http.post(url, payload);
  }

  delete(url: string, payload: object) {
    return this.http.post(url, payload);
  }
  deleteRequest(url: string, payload: object) {
    return this.http.delete(url, payload);
  }

  nonTableList(url: string) {
    return this.http.get(url);
  }
  maritalStatus(url: string) {
    return this.http.get(url);
  }
  countryById(url: string) {
    return this.http.get(url);
  }
  stateById(url: string) {
    return this.http.get(url);
  }
  createwithFile(url: string, payload: Object) {
    return this.http.post(url, payload, {
      headers: this.httpFileUploadHeaders,
    });
  }
  upcomingEvents(url: string) {
    return this.http.get(url);
  }
  getById(url: string) {
    return this.http.get(url);
  }
  approveOrReject(url: string, payload = null) {
    return this.http.post(url, payload);
  }
  exportToExcel(url: string): Observable<any> {
    return this.http.get(url, {
      headers: this.httpHeaders,
      responseType: 'blob',
      observe: 'response',
    });
  }

  exportToPDF(url: string): Observable<any> {
    return this.http.get(url, {
      headers: this.httpHeaders,
      responseType: 'blob',
    });
  }
  getAllActiveDiscounts(url: string, payload: FormData) {
    return this.http.get(url);
  }
  calculateDiscount(url: string, payload: FormData) {
    return this.http.post(url, payload);
  }

  cancelDonation(url: string, payload: FormData) {
    console.log('before service');

    return this.http.post(url, payload);
  }
  unSubscribePayment(url: string, id: string) {
    let finalUrl = url + id;
    return this.http.post(finalUrl, '');
  }
}
