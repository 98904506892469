export class HeaderConstants {
  public static marriageListHeader = [
    {
      name: 'created_at',
      displayName: 'Creation Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'groom_full_name',
      displayName: 'Groom',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'bride_full_name',
      displayName: 'Bride',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'ceremony_address',
      displayName: 'Address',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'marriage_date',
      displayName: 'Ceremony date',
      disableSorting: false,
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
    },
    {
      name: 'contact_number',
      displayName: 'Contact no',
      disableSorting: false,
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];

  public static discountListHeader = [
    {
      name: 'name',
      displayName: 'name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'value',
      displayName: 'value',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'discount_type',
      displayName: 'type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'isActive',
      displayName: 'Status',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];

  public static matrimonialListHeader = [
    {
      name: 'created_at',
      displayName: 'Creation Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'full_name',
      displayName: 'Full name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
    },
    {
      name: 'age',
      displayName: 'Age',
      disableSorting: false,
    },
    {
      name: 'gender',
      displayName: 'Gender',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static funeralListHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },
    {
      name: 'created_at',
      displayName: 'Creation Date',
      disableSorting: false,
    },
    {
      name: 'funeral_executor_name',
      displayName: 'Person',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
    },
    {
      name: 'grave_number',
      displayName: 'Grave number',
      disableSorting: false,
    },
    {
      name: 'funeral_payment_sts',
      displayName: 'Payment status',
      disableSorting: false,
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static eventListHeader = [
    {
      name: 'event_title',
      displayName: 'Title',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'event_organizer',
      displayName: 'Organizer',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'event_date',
      displayName: 'Date',
      disableSorting: false,
    },
    {
      name: 'event_type',
      displayName: 'Type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'event_cost',
      displayName: 'Cost (in CAD)',
      disableSorting: false,
    },
    {
      name: 'approval_status',
      displayName: 'Approval Status',
      disableSorting: false,
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static myEventListHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },
    {
      name: 'event_date',
      displayName: 'Event Date',
      disableSorting: false,
    },
    {
      name: 'event_title',
      displayName: 'Title',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'full_name',
      displayName: 'Name',
      disableSorting: false,
    },
    {
      name: 'event_venue',
      displayName: 'Event Venue',
      disableSorting: false,
      class: 'text-transform',
    },

    {
      name: 'event_cost',
      displayName: 'Cost (in CAD)',
      disableSorting: false,
    },
    {
      name: 'myEvent_payment_sts',
      displayName: 'Payment Status',
      disableSorting: false,
      class: 'text-transform',
    },

    {
      name: 'mode_payment',
      displayName: 'Mode Of Payment',
      disableSorting: false,
      class: 'text-transform',
    },

    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static eventParticipants = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },
    {
      name: 'event_date',
      displayName: 'Event Date',
      disableSorting: false,
    },
    {
      name: 'full_name',
      displayName: 'Full Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'contact_number',
      displayName: 'Contact Number',
      disableSorting: false,
    },
    {
      name: 'gender',
      displayName: 'Gender',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'age',
      displayName: 'Age',
      disableSorting: false,
    },
    {
      name: 'eventPayment_sts',
      displayName: 'Payment Status',
      disableSorting: false,
    },
    {
      name: 'mode_payment',
      displayName: 'Mode Of Payment',
      disableSorting: false,
    },
    {
      name: 'amount_needed',
      displayName: 'Amount (in CAD)',
      disableSorting: false,
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static academicListHeader = [
    {
      name: 'title',
      displayName: 'Title',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'register_amount',
      displayName: 'Registration Amount',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'start_month',
      displayName: 'From month',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'start_year',
      displayName: 'From year',
      disableSorting: false,
    },
    {
      name: 'end_month',
      displayName: 'To month',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'end_year',
      displayName: 'To year',
      disableSorting: false,
    },

    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static studentFeeListHeader = [
    {
      name: 'option_name',
      displayName: 'Option name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'cost',
      displayName: 'Cost $ (in CAD)',
      disableSorting: false,
    },
    {
      name: 'school_type',
      displayName: 'School type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'discount_name',
      displayName: 'Discount',
      disableSorting: true,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static schoolListHeader = [
    {
      name: 'school_type',
      displayName: 'School type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_status',
      displayName: 'Payment Status',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'roll_no',
      displayName: 'Registration no',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'contact_number',
      displayName: 'Contact Number',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'academic_year',
      displayName: 'Academic year',
      disableSorting: false,
    },
    {
      name: 'student_name',
      displayName: 'Student full name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'student_class',
      displayName: 'Student class',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'dob',
      displayName: 'Birth date',
      disableSorting: false,
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static weekEndSchoolListHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },
    {
      name: 'academic_year',
      displayName: 'Academic Year',
      disableSorting: false,
    },
    {
      name: 'parent_full_name',
      displayName: 'Parent Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'contact_number',
      displayName: 'Contact number',
      disableSorting: false,
    },
    {
      name: 'approval_status',
      displayName: 'Approval Status',
      disableSorting: false,
    },
    {
      name: 'school_payment_sts',
      displayName: 'Registration Status',
      disableSorting: false,
    },
    {
      name: 'student_fees_status',
      displayName: 'Fees Status',
      disableSorting: false,
    },
    {
      name: 'registration_status',
      displayName: 'Active Status',
      disableSorting: false,
    },

    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];

  public static AllDonationsListHeader = [
    {
      name: 'donor_fullname',
      displayName: 'Donor name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'donation_type',
      displayName: 'Purpose of donation',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'donation_amount',
      displayName: 'Donation amount (in CAD)',
      disableSorting: false,
    },
    {
      name: 'recurring_count',
      displayName: 'Recurring Schedule',
      disableSorting: false,
    },
    {
      name: 'payment_method',
      displayName: 'Payment type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'receipt_no',
      displayName: 'Tax Receipt No.',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_date',
      displayName: 'Payment date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'end_date',
      displayName: 'End Date',
      disableSorting: false,
      class: 'text-center',
    },
    {
      name: 'donation_Payment_sts',
      displayName: 'Payment status',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static AllDonationListHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },

    {
      name: 'donor_fullname',
      displayName: 'Donor name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'donation_type',
      displayName: 'Purpose of donation',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'donation_amount',
      displayName: 'Donation amount (in CAD)',
      disableSorting: false,
    },
    {
      name: 'recurring_count',
      displayName: 'Recurring Schedule',
      disableSorting: false,
    },
    {
      name: 'payment_method',
      displayName: 'Payment type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'receipt_no',
      displayName: 'Tax Receipt No.',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_date',
      displayName: 'Payment date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'end_date',
      displayName: 'End Date',
      disableSorting: false,
      class: 'text-center',
    },
    {
      name: 'donation_Payment_sts',
      displayName: 'Payment status',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static purposeOfDonationsListHeader = [
    {
      name: 'type_name',
      displayName: 'Purpose of donation',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static expenseCategoryListHeader = [
    {
      name: 'id',
      displayName: 'Category id',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'cat_name',
      displayName: 'Category name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static expenseListHeader = [
    {
      name: 'created_at',
      displayName: 'Date of Entry',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'expense_cat_id',
      displayName: 'Category',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'paid_to',
      displayName: 'Paid to',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'description',
      displayName: 'Description',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_method',
      displayName: 'Mode of payment',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'account_number',
      displayName: 'Account number',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'cheque_number',
      displayName: 'Cheque No./Trx No.',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'hst',
      displayName: 'HST (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'amount',
      displayName: 'Amount (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'total',
      displayName: 'Total (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },

    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static expenseAccountListHeader = [
    {
      name: 'id',
      displayName: 'Id',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'account_number',
      displayName: 'Account number',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static expenseReportListHeader = [
    {
      name: 'created_at',
      displayName: 'Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'expense_cat_id',
      displayName: 'Category',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'paid_to',
      displayName: 'Paid to',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'description',
      displayName: 'Description',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_method',
      displayName: 'Mode of payment',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'account_number',
      displayName: 'Account number',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'cheque_number',
      displayName: 'Cheque No./Trx No.',
      disableSorting: false,
      class: 'text-transform width',
    },
    {
      name: 'hst',
      displayName: 'HST (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'amount',
      displayName: 'Amount (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'total',
      displayName: 'Total (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
  ];
  public static revenueReportListHeader = [
    {
      name: 'payment_date',
      displayName: 'Payment date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payable_type',
      displayName: 'Category',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'subCategory',
      displayName: 'Sub category',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payable_name',
      displayName: 'Donor name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'address',
      displayName: 'Details',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'payment_type',
      displayName: 'Mode of payment',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'unique_payment_transaction_code',
      displayName: 'Cheque No./Trx No.',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'account_number',
      displayName: 'Received A/C',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'receipt_no',
      displayName: 'Receipt no',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'amount',
      displayName: 'Amount (in CAD)',
      disableSorting: false,
      class: 'text-transform',
    },
  ];
  public static employeeListHeader = [
    {
      name: 'fullname',
      displayName: 'Full name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'contact_number',
      displayName: 'Contact number',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'clearance',
      displayName: 'Clearance',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'birthdate',
      displayName: 'Birth date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'emp_code',
      displayName: 'Employee Code',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static groupListHeader = [
    {
      name: 'group_id',
      displayName: 'ID',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'name',
      displayName: 'Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'description',
      displayName: 'Description',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'level',
      displayName: 'Level',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static userListHeader = [
    {
      name: 'group_id',
      displayName: 'Group',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'username',
      displayName: 'Username',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'first_name',
      displayName: 'First Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
    },
    {
      name: 'active',
      displayName: 'Status',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static DiscountHeader = [
    {
      name: 'name',
      displayName: 'Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'code',
      displayName: 'Code',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'type',
      displayName: 'Discount Amount/Percentage',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'valid_upto',
      displayName: 'Redeem By',
      disableSorting: false,
      value: 'value',
    },

    {
      name: 'max_allowed',
      displayName: 'Max Redemptions',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static myCourseListHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },
    {
      name: 'course_title',
      displayName: 'Course Title',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'category_name',
      displayName: 'Category',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'insructor_name',
      displayName: 'Instructor',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'course_start_date',
      displayName: ' Course Start Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'course_duration',
      displayName: 'Course Duration',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'course_fee',
      displayName: 'Course Fee',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'discount_value',
      displayName: 'Discount Amount',
      disableSorting: false,
    },
    {
      name: 'pay_amount',
      displayName: 'Payment Amount',
      disableSorting: false,
    },
    {
      name: 'recurring_count',
      displayName: 'Recurring Schedule',
      disableSorting: false,
    },

    {
      name: 'myCourse_payment_sts',
      displayName: 'Payment Status',
      disableSorting: false,
      class: 'text-transform',
    },
    // {
    //   'name': 'payment_amount',
    //   'displayName': 'Payment Amount',
    //   'disableSorting': false,
    //   'class': "text-transform"
    // },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static courseRegisterHeader = [
    {
      name: 'entry_by',
      displayName: 'Course Register',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actual_course_fee',
      displayName: 'Course Fee',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'discount_code',
      displayName: 'Discount Code',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'payment_amount',
      displayName: 'Payment Amount',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'no_of_child',
      displayName: 'No of Student',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'course_Registered_Course_payment',
      displayName: 'Payment Status',
      disableSorting: false,
      class: 'text-transform',
    },
    // {
    //   'name': 'payment_amount',
    //   'displayName': 'Payment Amount',
    //   'disableSorting': false,
    //   'class': "text-transform"
    // },
    // {
    //   'name': 'actions',
    //   'displayName': 'Action',
    //   'disableSorting': true,
    // },
  ];
  public static courseRegisterStudentHeader = [
    {
      name: 'created_at',
      displayName: 'Creation Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'name',
      displayName: 'Student Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'dob',
      displayName: 'Date of Birth',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'gender',
      displayName: 'Gender',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'email',
      displayName: 'Email',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'contact_number',
      displayName: 'Contact Number',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'pickup_person_name',
      displayName: 'Pickup Person Name',
      disableSorting: false,
      class: 'text-transform',
    },
    // {
    //   'name': 'payment_amount',
    //   'displayName': 'Payment Amount',
    //   'disableSorting': false,
    //   'class': "text-transform"
    // },
  ];
  public static studentClassHeader = [
    {
      name: 'class_name',
      displayName: 'Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'school_type',
      displayName: 'School Type',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];

  public static ViewCourseRegisterHeader = [
    {
      name: 'payment',
      displayName: 'Payment',
      disableSorting: false,
    },

    {
      name: 'entry_by',
      displayName: 'Course Register',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actual_course_fee',
      displayName: 'Course Fee',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'discount_code',
      displayName: 'Discount Code',
      disableSorting: false,
      value: 'value',
    },
    {
      name: 'payment_amount',
      displayName: 'Payment Amount',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'no_of_child',
      displayName: 'No of Student',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'viewcourse_Registered_Course_payment',
      displayName: 'Payment Status',
      disableSorting: false,
      class: 'text-transform',
    },
    // {
    //   'name': 'payment_amount',
    //   'displayName': 'Payment Amount',
    //   'disableSorting': false,
    //   'class': "text-transform"
    // },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
  public static paymentReceiptHeader = [
    {
      name: 'receiptName',
      displayName: 'Receipt Name',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'invoiceDate',
      displayName: 'Invoice Date',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'amount',
      displayName: 'Amount',
      disableSorting: false,
      class: 'text-transform',
    },
    {
      name: 'actions',
      displayName: 'Action',
      disableSorting: true,
    },
  ];
}
