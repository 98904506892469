<mat-card class="view-page mat-card mat-focus-indicator p-0 mx-3">
    <div class="form-field-full">
        <div class="card">            
            <div class="card-body">
                <div class="col-12 p-0 d-flex align-items-baseline justify-content-between">
                    <h1 class="title">View Donation</h1>
                    <button class="btn btn-primary" [routerLink]="['/donations/alldonation']" name="back"><i
                            class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                </div>
                <div class="col-12 view-content table-responsive">
                    <table class="table  table-bordered" *ngIf="!formLoader; else showLoader">
                        <tbody>
                            <tr>
                                <td width="30%" class="label-view text-right">Donor name</td>
                                <td>{{data.fname | titlecase}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Last name</td>
                                <td>{{data.lname | titlecase}} </td>
                            </tr>

                            <tr>
                                <td width="30%" class="label-view text-right">Email</td>
                                <td>{{data.email}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Contact number</td>
                                <td>{{data.contact_number}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Purpose of donation</td>
                                <td>{{data.donation_type | titlecase}} </td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Donation amount</td>
                                <td>{{data.donation_amount + ' CAD'}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Country</td>
                                <td>{{data.country}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">State</td>
                                <td>{{data.province}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">City</td>
                                <td>{{data.city}}</td>
                            </tr>
                            <tr>
                                <td width="30%" class="label-view text-right">Payment type</td>
                                <td>{{data.payment_method == '1'? 'Cash' : data.payment_method == '2'? 'Cheque' : data.payment_method == '3'? 'E-Transfer' : data.payment_method == '4' ? 'Online' : 'POS'   }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <ng-template #showLoader>
                        <div class="text-center loader">
                            <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                color="accent">
                            </mat-progress-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</mat-card>