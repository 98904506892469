import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.css'],
})
export class ApprovalDialogComponent extends BaseComponent implements OnInit {
  message: string = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogdata: any,
    private dialogRef: MatDialogRef<ApprovalDialogComponent>,
    service: DataManagerService,
  ) {
    super();
    this.service = service;
    if (this.dialogdata) {
      console.log(this.dialogdata);
      this.message = dialogdata.message || this.message;
      if (dialogdata.buttonText) {
        this.confirmButtonText =
          dialogdata.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText =
          dialogdata.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.loadFlag2 = true;
    if (
      this.dialogdata.data.actions == 'reject' ||
      this.dialogdata.data.actions == 'approve'
    ) {
      this.service.approveOrReject(this.dialogdata.url).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogdata.data.actions == 'Verify Payment') {
      let payload: any;
      payload = {
        payment_method:
          this.dialogdata.data.data.payment_method == 'Cash'
            ? 1
            : this.dialogdata.data.data.payment_method == 'Cheque'
            ? 2
            : this.dialogdata.data.data.payment_method == 'E-Transfer'
            ? 3
            : this.dialogdata.data.data.payment_method == 'Online'
            ? 4
            : 5,
      };
      this.dialogdata.data.data.school_payment
        ? (payload['school_id'] =
            this.dialogdata.data.data.school_payment[0]?.sc_reg_id)
        : console.log('');
      this.service.create(this.dialogdata.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogdata.data.actions == 'downloadPDF') {
      this.service.exportToPDF(this.dialogdata.url).subscribe({
        next: (res: any) => {
          if (res) {
            this.blob = new Blob([res], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(res);
            var a = document.createElement('a');
            a.href = downloadURL;
            a.setAttribute('target', '_blank');
            a.click();
            this.dialogRef.close(true);
          } else {
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogdata.data.actions == 'payment') {
      let payload = {
        payment_method:
          this.dialogdata.data.data.payment_method == 'Cash'
            ? 1
            : this.dialogdata.data.data.payment_method == 'Cheque'
            ? 2
            : this.dialogdata.data.data.payment_method == 'E-Transfer'
            ? 3
            : this.dialogdata.data.data.payment_method == 'Online'
            ? 4
            : 5,
      };
      this.service.create(this.dialogdata.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success' && res.data) {
            this.dialogRef.close(res.data);
          } else if (res['status'] == 'success' && !res.data) {
            this.dialogRef.close(false);
            this.service.showSnackBar(res.message, 'ok', 3000);
          } else {
            this.dialogRef.close(false);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.dialogRef.close(false);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogdata.data.actions == 'active') {
      this.service.approveOrReject(this.dialogdata.url).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else if (this.dialogdata.data.actions == 'Unsubscribe') {
      const payload: FormData = new FormData();
      payload.append('rbAccountId', this.dialogdata.data.data.account_id);
      payload.append('operation', '3');
      this.service.cancelDonation(this.dialogdata.url, payload).subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.service.showSnackBar(res.message, 'ok', 3000);
            this.dialogRef.close(true);
          } else {
            this.loadFlag2 = false;
            this.dialogRef.close(true);
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
        error: (error) => {
          this.loadFlag2 = false;
          this.dialogRef.close(true);
          this.service.showSnackBar(error.message, 'ok', 3000);
        },
      });
    } else {
      this.dialogRef.close(false);
    }
  }
  ngOnInit(): void {}
}
