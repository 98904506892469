import { CurrencyPipe } from '@angular/common';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { AppConstants } from '../constants/app-constants';

export abstract class BaseComponent {
  public service: DataManagerService;
  public permissionsCheck: any;
  public create_permission: boolean;
  public edit_permission: boolean;
  public view_permission: boolean;
  public delete_permission: boolean;
  public update_permission: boolean;
  public list_permission: boolean;
  public approve_permission: boolean;
  public excel_permission: boolean;
  public currencyPipe: CurrencyPipe;
  public submitted: boolean;
  public headings: string;
  public data: any;
  public buttonText: string;
  public schoolsType = [
    { key: 1, name: 'Weekend' },
    { key: 2, name: 'Al-Furqan' },
  ];
  public months = [
    { key: 1, name: 'January' },
    { key: 2, name: 'February' },
    { key: 3, name: 'March' },
    { key: 4, name: 'April' },
    { key: 5, name: 'May' },
    { key: 6, name: 'June' },
    { key: 7, name: 'July' },
    { key: 8, name: 'August' },
    { key: 9, name: 'September' },
    { key: 10, name: 'October' },
    { key: 11, name: 'November' },
    { key: 12, name: 'December' },
  ];
  public topErrorMessage: any;
  public keys: string[] = [];
  public keys1: any[] = [];
  public years: any[] = [];
  public languagesPreference = [
    { name: 'Arabic', checked: 0, value: 'IsArabicSpeak' },
    { name: 'English', checked: 0, value: 'IsEnglishSpeak' },
    { name: 'Other', checked: 0, value: 'IsOtherSpeak' },
  ];
  public loadFlag2: boolean;
  public countryListUrl: string;
  public expenseAccountListUrl: string;
  public maritalStatusUrl: string;
  public stateListUrl: string;
  public cityListUrl: string;
  public countryList: string[] = [];
  public selectdCountry: string = '';
  public stateList: string[] = [];
  public selectdState: string = '';
  public cityList: string[] = [];
  public selectdCity: string = '';
  public formLoader: boolean = false;
  public dontationTypeList: any[] = [];
  public expenseCategoryList: string[] = [];
  public blob: Blob;
  public expenseAccountList: string[] = [];
  public paymentMethodList: string[] = [];
  currentIndex: any = 0;
  maxError: boolean = false;
  minError: boolean = false;

  matchUrl(url: any): any {
    return location.pathname.match(url);
  }

  /** To find Null or undefined
   * @param value
   * @function isNullOrUndefined()
   */
  isNullOrUndefined(value: any) {
    return value == null || typeof value == undefined;
  }
  /** To find Null or not
   * @param value
   * @function isNull()
   */
  isNull(value: any) {
    return value == null;
  }
  numericOnly(event: any) {
    const keypressed = event.which || event.keyCode;

    if (
      (keypressed >= 48 && keypressed <= 57) || // digits
      keypressed === 8 || // backspace
      keypressed === 9 || // tab
      keypressed === 27 || // escape
      event.key === '-' || // dash
      event.key === 'Delete' || // delete
      (keypressed >= 35 && keypressed <= 38) || // end, home, Leftarrows
      event.key === 'ArrowRight' // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  letterOnly(event: any): Boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 32 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }
  letterwithchar(event: any): Boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 95 &&
      charCode != 46 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  phoneMask(event: any) {
    if (event) {
      const phone = event.match(/(\d{3})(\d{3})(\d{4})/);
      event = '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
      return event;
    }
  }

  /**
   * func to check for user permission on actions
   * @param permissionStr
   */
  permissionCheck(permissionStr: string): boolean {
    return this.service.permissionsArray.find(
      (permissionObj: any) => permissionObj.permission_name === permissionStr,
    )
      ? true
      : false;
  }
  formatMoney(value: any) {
    const temp = `${value}`.replace(/\,/g, '');
    return this.currencyPipe.transform(temp)?.replace('$', '');
  }

  getCountryList() {
    this.service.nonTableList(this.countryListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.countryList = res.data;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
    });
  }

  changeCountry() {
    this.service
      .countryById(this.stateListUrl + '/' + this.selectdCountry)
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.stateList = res.data;
            this.changeState();
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
      });
  }
  changeState() {
    this.service
      .stateById(this.cityListUrl + '/' + this.selectdState)
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.cityList = res.data;
            this.getViewData();
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
      });
  }
  downloadExcel(res: any) {
    this.blob = new Blob([res['body']], { type: 'application/vnd.ms-excel' });
    var downloadURL = window.URL.createObjectURL(res['body']);
    var link = document.createElement('a');
    link.href = downloadURL;
    var contentDisposition = res.headers.get('content-disposition');
    var filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim();
    link.download = filename;
    link.click();
  }

  getExpenseAccount() {
    this.service.nonTableList(this.expenseAccountListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.expenseAccountList = res.data;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
    });
  }
  toggleViewMode(param: string) {
    if (param === 'password') {
      const togglePassword = document.querySelector('#togglePassword');
      const password = document.querySelector('#password');
      if (togglePassword && password) {
        const type =
          password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        togglePassword.classList.toggle('fa-eye-slash');
      }
    } else {
      const togglePassword = document.querySelector('#cPtogglePassword');
      const password = document.querySelector('#confirm_password');
      if (togglePassword && password) {
        const type =
          password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        togglePassword.classList.toggle('fa-eye-slash');
      }
    }
  }
  expand(index: any) {
    if (Number(this.currentIndex) === index && index == 0) {
      this.currentIndex = 0;
      return;
    } else if (Number(this.currentIndex) === index && index >= 0) {
      this.currentIndex = null;
    } else {
      this.currentIndex = index;
    }
  }
  minMaxCheck(amount: any) {
    if (amount < 0.01) {
      this.maxError = false;
      this.minError = true;
    } else {
      this.minError = false;
      this.maxError = false;
    }
    if (amount >= 10000000) {
      this.maxError = true;
      this.minError = false;
    }
  }
  _keyPress(event: any) {
    console.log(event);
    const keypressed = event.which || event.keyCode;
    console.log(keypressed);
    if (
      (keypressed >= 48 && keypressed <= 57) || // digits
      keypressed === 8 || // backspace
      keypressed === 9 || // tab
      keypressed === 27 || // escape
      keypressed === 46 || // delete
      (keypressed >= 35 && keypressed <= 38) || // end, home, Leftarrows
      event.key === 'ArrowRight' // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  getViewData() {
    this.countryList.forEach((element: any) => {
      if (element.id == this.data.country) {
        this.data.country = element.country_name;
      }
    });
    this.stateList.forEach((element: any) => {
      if (element.id == this.selectdState) {
        this.data.province = element.state_name;
      }
    });
    this.cityList.forEach((element: any) => {
      if (element.id == this.data.city) {
        this.data.city = element.city_name;
      }
    });
    this.formLoader = false;
  }
  onChangeCountry() {
    this.service
      .countryById(this.stateListUrl + '/' + this.selectdCountry)
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.stateList = res.data;
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
      });
  }
  onChangeState() {
    this.service
      .stateById(this.cityListUrl + '/' + this.selectdState)
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success') {
            this.cityList = res.data;
          } else {
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        },
      });
  }
}
