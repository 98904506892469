<div class="container">
    <!-- <div class="login-logo">
        <img src="https://nsicc.ca/wp-content/uploads/2023/01/Donate-Header.png" alt="Login Logo">
    </div> -->

    <div class="card">
        <mat-toolbar class="main-header">
            <mat-toolbar-row>
                <span>{{ headings }}</span>
                <span class="example-spacer"></span>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body">
            <div class="form-ajax-box">
                <ng-container *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                        <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                            <p class="col-md-12 error text-center" id="err">{{value}}</p>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error">
                        {{errorMsg}}
                    </div>
                </div>
                <form class="form-horizontal nsicc-form validated" [formGroup]="donationForm">
                    <div class=" col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 " *ngIf=" !formLoader; else
                    showLoader">
                        <div class="row">
                            <label class="form-label" for="donation_type">Purpose of donation <span class="asterix">
                                    *
                                </span></label>
                            <mat-form-field class="centered-input">
                                <select matNativeControl aria-label="Default select example"
                                    [ngModelOptions]="{ updateOn: 'submit' }" formControlName="donation_type"
                                    id="donation_type"
                                    [ngClass]="{ 'is-invalid': submitted && donationFormControl.donation_type.errors }">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let data of dontationTypeList" [ngValue]="data.donation_type_id">
                                        {{data.type_name}}
                                    </option>
                                </select>
                            </mat-form-field>
                            <div *ngIf="submitted && donationFormControl.donation_type.errors"
                                class="invalid-feedback p-0">
                                <div *ngIf="donationFormControl.donation_type?.errors?.required">Purpose of
                                    donation
                                    is
                                    required.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group  ">
                            <label class=" form-label " for="donation_amount"> Choose an Amount or Enter Custom Amount
                                <span class="asterix">
                                    *
                                </span></label>
                        </div>
                        <div class="row form-group cashButtonGroup">
                            <div class="col-xl-1  col-md-2 col-sm-2 col-4">
                                <button class="cashButton" (click)="changeCash(10)" mat-raised-button>$10</button>
                            </div>
                            <div class="col-xl-1 col-sm-2 col-md-2 col-4">
                                <button class="cashButton" (click)="changeCash(20)" mat-raised-button>$20</button>
                            </div>
                            <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                <button class="cashButton" (click)="changeCash(50)" mat-raised-button>$50</button>
                            </div>
                            <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                <button class="cashButton" (click)="changeCash(100)" mat-raised-button>$100</button>
                            </div>
                            <div class="col-xl-1 col-sm-2 col-md-2 col-4">

                                <button class="cashButton" (click)="changeCash(1000)" mat-raised-button>$1000</button>
                            </div>
                        </div>
                        <div class="row">
                            <mat-form-field class="centered-input">
                                <input matInput type="number" name="donation_amount" id="donation_amount" max="1000000"
                                    [(ngModel)]="amount" formControlName="donation_amount" (keyup)="minMaxCheck(amount)"
                                    (keypress)="_keyPress($event)" autocomplete="off" step="1" placeholder="$ Amount"
                                    [ngClass]="{ 'is-invalid': submitted && donationFormControl.donation_amount.errors }">
                                <mat-error *ngIf="submitted && donationFormControl.donation_amount.errors  && minError">
                                    Minimum amount is 0.01.
                                </mat-error>
                                <mat-error *ngIf="!donationFormControl.donation_amount
                                    .errors?.required && maxError">
                                    Maximum amount is 9999999.99.
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="submitted && donationFormControl.donation_amount.errors"
                                class="invalid-feedback">
                                <div *ngIf="donationFormControl.donation_amount?.errors?.required">Donation amount
                                    is
                                    required.
                                </div>
                                <div *ngIf="!donationFormControl.donation_amount.errors?.required && minError">
                                    Amount should not be less than
                                    $0.01.</div>
                                <div *ngIf="!donationFormControl.donation_amount
                                      .errors?.required && maxError">Amount should not be greater than
                                    $10000000.</div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <label class="form-label mb-4" for="donation_type">Contact Information

                            </label>
                            <div class="form-group  col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <mat-form-field class="form-full-width">
                                    <mat-label for="fname"> First name </mat-label>
                                    <input matInput type="text" name="fname" id="fname" formControlName="fname"
                                        maxlength="20" (keypress)="letterOnly($event)">
                                </mat-form-field>
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <mat-form-field class="form-full-width">
                                    <mat-label for="lname"> Last name </mat-label>
                                    <input matInput type="text" name="lname" id="lname" formControlName="lname"
                                        maxlength="20" (keypress)="letterOnly($event)">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <mat-form-field class="form-full-width">
                                    <mat-label for="email"> Email</mat-label>
                                    <input matInput type="text" name="email" id="email" formControlName="email"
                                        pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                        [ngClass]="{ 'is-invalid': submitted && donationFormControl.email.errors }">
                                </mat-form-field>
                                <div *ngIf="submitted && donationFormControl.email.errors" class="invalid-feedback p-0">
                                    <div *ngIf="donationFormControl.email?.errors?.required">Email is
                                        required.
                                    </div>
                                    <div *ngIf="donationFormControl.email.errors.pattern">Invaild email.</div>
                                </div>
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">

                                <span
                                    class="numberone input-group-addon last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2 ">+1</span>
                                <mat-form-field
                                    class="form-full-width phone col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10">
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput placeholder="(000) 000-0000" type="tel" appPhoneMask maxlength="14"
                                        autocomplete="off" name="phone" formControlName="contact_number" id="phone"
                                        [ngClass]="{ 'is-invalid': submitted && donationFormControl.contact_number.errors }">
                                </mat-form-field>
                                <div *ngIf="submitted && donationFormControl.contact_number.errors"
                                    class="invalid-feedback p-0">
                                    <div *ngIf="donationFormControl.contact_number.errors.required">Contact is
                                        required.
                                    </div>
                                    <div *ngIf="donationFormControl.contact_number.errors.pattern">Invalid
                                        contact
                                        number.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <label class="form-label" for="country"> Country </label>
                                <mat-form-field class="centered-input">
                                    <select matNativeControl [(ngModel)]="selectdCountry"
                                        aria-label="Default select example" formControlName="country"
                                        (change)="onChangeCountry()" id="country">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let data of countryList" [ngValue]="data.id">
                                            {{data.country_name}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <label class="form-label" for="province"> Province </label>
                                <mat-form-field class="centered-input">
                                    <select matNativeControl [(ngModel)]="selectdState"
                                        aria-label="Default select example" formControlName="state"
                                        (change)="onChangeState()" id="province">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let state of stateList" [ngValue]="state.id">
                                            {{state.state_name}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <label class="form-label" for="city"> City </label>
                                <mat-form-field class="centered-input">
                                    <select matNativeControl [(ngModel)]="selectdCity"
                                        aria-label="Default select example" id="city" formControlName="city"
                                        (change)="onChangeState()">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let city of cityList" [ngValue]="city.id">{{city.city_name}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                <label for="payment_method" class="form-label col-12 p-0 mt-2"> Mode Of Payment
                                    <span class="asterix">
                                        *
                                    </span></label>
                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">

                                    <label class="form-label" class="text-muted form-check-label mr-5" for="Cash"
                                        *ngIf="showtoAdmin">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="1" class="filled-in form-check-input" id="payment_method-0"
                                            (change)="enableChequeNumber('Cash')"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"> <label class="form-label"
                                            for="payment_method-0">Cash
                                        </label>
                                    </label>
                                    <label class="form-label" class="form-check-label  mr-5" for="Cheque"
                                        *ngIf="showtoAdmin">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="2" class="filled-in form-check-input" id="payment_method-1"
                                            (change)="enableChequeNumber('Cheque')"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"><label class="form-label"
                                            for="payment_method-1">Cheque</label>
                                    </label>

                                    <label class="form-check-label  mr-5" for="payment_method-3">

                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="3" class="filled-in form-check-input" id="payment_method-3"
                                            (change)="changeName('E-Transfer') "
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"><label class="form-label"
                                            for="payment_method-3">E-Transfer</label>
                                    </label>

                                    <label class="form-check-label" for="payment_method-4">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="4" class="filled-in form-check-input" id="payment_method-4"
                                            (change)="changeName('Online')"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"><label class="form-label"
                                            for="payment_method-4">Online</label>
                                    </label>
                                </div>
                                <div *ngIf="submitted && donationFormControl.payment_method.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="donationFormControl.payment_method.errors.required">
                                        Mode of payment
                                        is
                                        required.
                                    </div>
                                </div>
                                <div id="epaymenInfo" *ngIf="donationForm.value.payment_method == 3"
                                    class="info col-12">
                                    <p class="">To send donations through EMAIL transfer from your bank account,
                                        please
                                        use the following information:</p>
                                    <address>
                                        Name: Kearney Lake Masjid<br>
                                        Email: <a href="mailto:treasurer@nsicc.ca">treasurer@nsicc.ca</a><br>
                                        Security Question: What is the donation for?<br>
                                        Security Answer: general<br>
                                    </address>
                                    <p>Enter the amount and purpose you want to donate and send</p>
                                </div>
                                <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6"
                                    *ngIf="donationForm.value.payment_method == 2">
                                    <label class="form-label" for="cheque_number"> Cheque number <span class="asterix">
                                            *
                                        </span></label>
                                    <mat-form-field class="form-full-width">
                                        <mat-label>Cheque Number</mat-label>
                                        <input matInput placeholder="" type=" tel" (keypress)="numericOnly($event)"
                                            maxlength="15" autocomplete="off" name="cheque_number"
                                            formControlName="cheque_number" id="phone"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.cheque_number.errors }">
                                    </mat-form-field>
                                    <div *ngIf="submitted && donationFormControl.cheque_number.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="donationFormControl.cheque_number.errors.required">Cheque number is
                                            required.
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="true && donationForm.value.payment_method != 4 && donationForm.value.payment_method != '' "
                                class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 ">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Donation Date</mat-label>
                                    <input matInput [matDatepicker]="picker" (click)="picker.open();"
                                        [ngClass]="{ 'is-invalid': (submitted && showDateError) ||  showDateError  }"
                                        formControlName="donation_date" id="donation_date">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                            </div>

                            <div class="row" *ngIf="donationForm.value.payment_method == 4">
                                <lable class="form-label">Payment Frequency <span class="asterix">
                                        *
                                    </span></lable>
                                <mat-radio-group id="payment_frequency" formControlName="payment_frequency"
                                    (change)="setFrequencyValidation()" aria-label="Select an option">
                                    <mat-radio-button name="payment_frequency" id="payment_frequency-1" value="1">One
                                        time
                                    </mat-radio-button>
                                    <mat-radio-button name="payment_frequency" id="payment_frequency-2"
                                        (change)="setRecurring('Days')" value="2">Daily
                                    </mat-radio-button>
                                    <mat-radio-button name="payment_frequency" id="payment_frequency-3"
                                        (change)="setRecurring('Weeks')" value="3">Weekly
                                    </mat-radio-button>
                                    <mat-radio-button name="payment_frequency" id="payment_frequency-4"
                                        (change)="setRecurring('Months')" value="4">Monthly
                                    </mat-radio-button>
                                    <mat-radio-button name="payment_frequency" id="payment_frequency-5"
                                        (change)="setRecurring('Years')" value="5">Yearly
                                    </mat-radio-button>
                                    <div *ngIf="submitted && donationFormControl.payment_frequency.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="donationFormControl.payment_frequency.errors.required">
                                            Payment Frequency
                                            is
                                            required.
                                        </div>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <div class="loading-overlay" *ngIf="isLoading">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">Loading...</div>
                            </div>
                            <div class="row"
                                *ngIf="donationForm.value.payment_method == 4 && 
                                donationForm.value.payment_frequency != 1 && donationForm.value.payment_frequency != ''">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="col-12">
                                        <label class="form-label"> Recurring Start Date <span class="asterix">
                                                *
                                            </span></label>
                                    </div>
                                    <div class="col-12 recurring_dates">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <input matInput [matDatepicker]="picker"
                                                (click)="picker.open();validateDate()" [min]="minDate"
                                                [ngClass]="{ 'is-invalid': (submitted && showDateError) ||  showDateError  }"
                                                (change)="validateDate()" [max]="maxDate"
                                                formControlName="recurring_date" id="recurring_date" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <mat-error *ngIf="submitted && donationForm.recurring_date?.errors"
                                                class="invalid-feedback">
                                                <mat-error class="error-message"
                                                    *ngIf="donationForm.recurring_date.errors?.required"> Recurring
                                                    Start
                                                    date is
                                                    required.
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="col-12">
                                        <label class="form-label">Number of {{recurringOption}} <span class="asterix">
                                                *
                                            </span></label>
                                    </div>
                                    <mat-form-field class="centered-input">
                                        <input matInput type="number" class="form-full-width" name="recurring_count"
                                            id="recurring_count" max="365" formControlName="recurring_count"
                                            (keypress)="numericOnly($event)" autocomplete="off" step="1"
                                            placeholder="{{ recurringOption }}" maxlength="3"
                                            [ngClass]="{ 'is-invalid': submitted && donationFormControl.recurring_count?.errors }">
                                        <mat-error
                                            *ngIf="submitted && donationFormControl.recurring_count?.errors?.required">
                                            Number of {{ recurringOption }} is required
                                        </mat-error>

                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row notice" id="notice"
                                *ngIf="donationForm.value.payment_method == 4 &&  donationForm.value.payment_frequency != 1  && donationForm.value.payment_frequency != '' && ! updateFlag">
                                <div class="col-12 form-group">
                                    <div class="alert alert-help ">
                                        <div class="alert-icon">
                                            <i class="fa fa-exclamation-triangle"></i>
                                        </div>
                                        <div class="alert-text">
                                            <h5> Please note:</h5>
                                            You will incur a $1 charge if the recurring start date is not the current
                                            date
                                            in order to verify the payment details provided. This charge will not be
                                            credited back to your account and you will receive a transaction
                                            receipt for this amount.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="donationForm.value.payment_method == 4 &&  donationForm.value.payment_frequency != 1  && donationForm.value.payment_frequency != '' && ! updateFlag">
                                <app-custom-checkout (sendToken)="onSubmit($event)" [amount]="amount"
                                    [payWord]="payWord" [message]="formMessage">
                                </app-custom-checkout>
                            </ng-container>


                            <div class="row" style="align-items:baseline;"
                                *ngIf="donationForm.value.payment_method == 3 || donationForm.value.payment_method == 2 ||
                                donationForm.value.payment_method == 1||
                           ( donationForm.value.payment_frequency == 1 && donationForm.value.payment_frequency != '' ) ||  updateFlag">
                                <div *ngIf="submitted && donationForm.invalid" class="invalid-feedback">
                                    Please Fill Required Fields
                                </div>

                                <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex">
                                    <div class="btn-group mr-2">
                                        <button (click)="onSubmit()" class="btn btn-primary" name="save">{{buttonText}}
                                            <i *ngIf="loadFlag2"
                                                class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                                    </div>

                                    <div class="btn-group">
                                        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ng-template #showLoader>
                        <div class="text-center loader">
                            <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                color="accent">
                            </mat-progress-spinner>
                        </div>
                    </ng-template>
                </form>
            </div>
        </div>
    </div>