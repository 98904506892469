export class PermissionConstants {
  public static PERMISSIONS_USERS_VIEW = 'users_view';
  public static PERMISSIONS_USERS_DETAIL = 'users_detail';
  public static PERMISSIONS_USERS_ADD = 'users_add';
  public static PERMISSIONS_USERS_EDIT = 'users_edit';
  public static PERMISSIONS_USERS_REMOVE = 'users_remove';
  public static PERMISSIONS_USERS_EXCEL = 'users_excel';
  public static PERMISSIONS_GROUPS_VIEW = 'groups_view';
  public static PERMISSIONS_GROUPS_DETAIL = 'groups_detail';
  public static PERMISSIONS_GROUPS_ADD = 'groups_add';
  public static PERMISSIONS_GROUPS_EDIT = 'groups_edit';
  public static PERMISSIONS_GROUPS_REMOVE = 'groups_remove';
  public static PERMISSIONS_GROUPS_EXCEL = 'groups_excel';
  public static PERMISSIONS_LOGS_VIEW = 'logs_view';
  public static PERMISSIONS_LOGS_DETAIL = 'logs_detail';
  public static PERMISSIONS_LOGS_REMOVE = 'logs_remove';
  public static PERMISSIONS_LOGS_EXCEL = 'logs_excel';
  public static PERMISSIONS_MATRIMONIAL_VIEW = 'matrimonial_view';
  public static PERMISSIONS_MATRIMONIAL_DETAIL = 'matrimonial_detail';
  public static PERMISSIONS_MATRIMONIAL_ADD = 'matrimonial_add';
  public static PERMISSIONS_MATRIMONIAL_EDIT = 'matrimonial_edit';
  public static PERMISSIONS_MATRIMONIAL_REMOVE = 'matrimonial_remove';
  public static PERMISSIONS_MATRIMONIAL_EXCEL = 'matrimonial_excel';
  public static PERMISSIONS_MARRIAGE_VIEW = 'marriage_view';
  public static PERMISSIONS_MARRIAGE_DETAIL = 'marriage_detail';
  public static PERMISSIONS_MARRIAGE_ADD = 'marriage_add';
  public static PERMISSIONS_MARRIAGE_EDIT = 'marriage_edit';
  public static PERMISSIONS_MARRIAGE_REMOVE = 'marriage_remove';
  public static PERMISSIONS_MARRIAGE_EXCEL = 'marriage_excel';
  public static PERMISSIONS_FUNERAL_VIEW = 'funeral_view';
  public static PERMISSIONS_FUNERAL_DETAIL = 'funeral_detail';
  public static PERMISSIONS_FUNERAL_ADD = 'funeral_add';
  public static PERMISSIONS_FUNERAL_EDIT = 'funeral_edit';
  public static PERMISSIONS_FUNERAL_REMOVE = 'funeral_remove';
  public static PERMISSIONS_FUNERAL_EXCEL = 'funeral_excel';
  public static PERMISSIONS_DONATION_VIEW = 'alldonation_view';
  public static PERMISSIONS_DONATION_DETAIL = 'alldonation_detail';
  public static PERMISSIONS_DONATION_ADD = 'alldonation_add';
  public static PERMISSIONS_DONATION_EDIT = 'alldonation_edit';
  public static PERMISSIONS_DONATION_REMOVE = 'alldonation_remove';
  public static PERMISSIONS_DONATION_EXCEL = 'alldonation_excel';
  public static PERMISSIONS_EXPENSECATEGORY_VIEW = 'expensecategory_view';
  public static PERMISSIONS_EXPENSECATEGORY_DETAIL = 'expensecategory_detail';
  public static PERMISSIONS_EXPENSECATEGORY_ADD = 'expensecategory_add';
  public static PERMISSIONS_EXPENSECATEGORY_EDIT = 'expensecategory_edit';
  public static PERMISSIONS_EXPENSECATEGORY_REMOVE = 'expensecategory_remove';
  public static PERMISSIONS_EXPENSECATEGORY_EXCEL = 'expensecategory_excel';
  public static PERMISSIONS_EXPENSEACCOUNT_VIEW = 'expenseaccount_view';
  public static PERMISSIONS_EXPENSEACCOUNT_DETAIL = 'expenseaccount_detail';
  public static PERMISSIONS_EXPENSEACCOUNT_ADD = 'expenseaccount_add';
  public static PERMISSIONS_EXPENSEACCOUNT_EDIT = 'expenseaccount_edit';
  public static PERMISSIONS_EXPENSEACCOUNT_REMOVE = 'expenseaccount_remove';
  public static PERMISSIONS_EXPENSEACCOUNT_EXCEL = 'expenseaccount_excel';
  public static PERMISSIONS_EXPENSE_VIEW = 'expense_view';
  public static PERMISSIONS_EXPENSE_DETAIL = 'expense_detail';
  public static PERMISSIONS_EXPENSE_ADD = 'expense_add';
  public static PERMISSIONS_EXPENSE_EDIT = 'expense_edit';
  public static PERMISSIONS_EXPENSE_REMOVE = 'expense_remove';
  public static PERMISSIONS_EXPENSE_EXCEL = 'expense_excel';
  public static PERMISSIONS_EMPLOYEE_VIEW = 'employee_view';
  public static PERMISSIONS_EMPLOYEE_DETAIL = 'employee_detail';
  public static PERMISSIONS_EMPLOYEE_ADD = 'employee_add';
  public static PERMISSIONS_EMPLOYEE_EDIT = 'employee_edit';
  public static PERMISSIONS_EMPLOYEE_REMOVE = 'employee_remove';
  public static PERMISSIONS_EMPLOYEE_EXCEL = 'employee_excel';
  public static PERMISSIONS_DONATIONTYPE_VIEW = 'donationtype_view';
  public static PERMISSIONS_DONATIONTYPE_DETAIL = 'donationtype_detail';
  public static PERMISSIONS_DONATIONTYPE_ADD = 'donationtype_add';
  public static PERMISSIONS_DONATIONTYPE_EDIT = 'donationtype_edit';
  public static PERMISSIONS_DONATIONTYPE_REMOVE = 'donationtype_remove';
  public static PERMISSIONS_DONATIONTYPE_EXCEL = 'donationtype_excel';
  public static PERMISSIONS_EVENT_VIEW = 'event_view';
  public static PERMISSIONS_EVENT_DETAIL = 'event_detail';
  public static PERMISSIONS_EVENT_ADD = 'event_add';
  public static PERMISSIONS_EVENT_EDIT = 'event_edit';
  public static PERMISSIONS_EVENT_REMOVE = 'event_remove';
  public static PERMISSIONS_EVENT_EXCEL = 'event_excel';
  public static PERMISSIONS_SCHOOLREGISTRATION_VIEW = 'schoolregistration_view';
  public static PERMISSIONS_SCHOOLREGISTRATION_DETAIL =
    'schoolregistration_detail';
  public static PERMISSIONS_SCHOOLREGISTRATION_ADD = 'schoolregistration_add';
  public static PERMISSIONS_SCHOOLREGISTRATION_EDIT = 'schoolregistration_edit';
  public static PERMISSIONS_SCHOOLREGISTRATION_REMOVE =
    'schoolregistration_remove';
  public static PERMISSIONS_SCHOOLREGISTRATION_EXCEL =
    'schoolregistration_excel';
  public static PERMISSIONS_STUDENTFEES_VIEW = 'studentfees_view';
  public static PERMISSIONS_STUDENTFEES_DETAIL = 'studentfees_detail';
  public static PERMISSIONS_STUDENTFEES_ADD = 'studentfees_add';
  public static PERMISSIONS_STUDENTFEES_EDIT = 'studentfees_edit';
  public static PERMISSIONS_STUDENTFEES_REMOVE = 'studentfees_remove';
  public static PERMISSIONS_STUDENTFEES_EXCEL = 'studentfees_excel';
  public static PERMISSIONS_STUDENTBASICDETAILS_VIEW =
    'studentbasicdetails_view';
  public static PERMISSIONS_STUDENTBASICDETAILS_DETAIL =
    'studentbasicdetails_detail';
  public static PERMISSIONS_STUDENTBASICDETAILS_ADD = 'studentbasicdetails_add';
  public static PERMISSIONS_STUDENTBASICDETAILS_EDIT =
    'studentbasicdetails_edit';
  public static PERMISSIONS_STUDENTBASICDETAILS_REMOVE =
    'studentbasicdetails_remove';
  public static PERMISSIONS_STUDENTBASICDETAILS_EXCEL =
    'studentbasicdetails_excel';
  public static PERMISSIONS_ACADEMICYEAR_VIEW = 'academicyear_view';
  public static PERMISSIONS_ACADEMICYEAR_DETAIL = 'academicyear_detail';
  public static PERMISSIONS_ACADEMICYEAR_ADD = 'academicyear_add';
  public static PERMISSIONS_ACADEMICYEAR_EDIT = 'academicyear_edit';
  public static PERMISSIONS_ACADEMICYEAR_REMOVE = 'academicyear_remove';
  public static PERMISSIONS_ACADEMICYEAR_EXCEL = 'academicyear_excel';
  public static PERMISSIONS_PERMISSION_VIEW = 'permission_view';
  public static PERMISSIONS_PERMISSION_DETAIL = 'permission_detail';
  public static PERMISSIONS_PERMISSION_ADD = 'permission_add';
  public static PERMISSIONS_PERMISSION_EDIT = 'permission_edit';
  public static PERMISSIONS_PERMISSION_REMOVE = 'permission_remove';
  public static PERMISSIONS_PERMISSION_EXCEL = 'permission_excel';
  public static PERMISSIONS_MANAGEAPPROVAL_VIEW = 'manageapproval_view';
  public static PERMISSIONS_MANAGEAPPROVAL_DETAIL = 'manageapproval_detail';
  public static PERMISSIONS_MANAGEAPPROVAL_ADD = 'manageapproval_add';
  public static PERMISSIONS_MANAGEAPPROVAL_EDIT = 'manageapproval_edit';
  public static PERMISSIONS_MANAGEAPPROVAL_REMOVE = 'manageapproval_remove';
  public static PERMISSIONS_MANAGEAPPROVAL_EXCEL = 'manageapproval_excel';
  public static PERMISSIONS_SYSTEMSETTINGS_VIEW = 'systemsettings_view';
  public static PERMISSIONS_SYSTEMSETTINGS_DETAIL = 'systemsettings_detail';
  public static PERMISSIONS_SYSTEMSETTINGS_ADD = 'systemsettings_add';
  public static PERMISSIONS_SYSTEMSETTINGS_EDIT = 'systemsettings_edit';
  public static PERMISSIONS_SYSTEMSETTINGS_REMOVE = 'systemsettings_remove';
  public static PERMISSIONS_SYSTEMSETTINGS_EXCEL = 'systemsettings_excel';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_VIEW =
    'WeekdaySchoolRegistration_view';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_DETAIL =
    'WeekdaySchoolRegistration_detail';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_ADD =
    'WeekdaySchoolRegistration_add';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_EDIT =
    'WeekdaySchoolRegistration_edit';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_REMOVE =
    'WeekdaySchoolRegistration_remove';
  public static PERMISSIONS_WEEKDAYSCHOOLREGISTRATION_EXCEL =
    'WeekdaySchoolRegistration_excel';
  public static PERMISSIONS_MYEVENTS_VIEW = 'myevents_view';
  public static PERMISSIONS_MYEVENTS_DETAILS = 'myevents_detail';
  public static DASHBOARD_VIEW = 'dashboard_view';
  public static PERMISSIONS_COURSE_VIEW = 'courses_view';
  public static PERMISSIONS_COURSE_DETAILS = 'courses_detail';
  public static PERMISSIONS_COURSE_ADD = 'courses_add';
  public static PERMISSIONS_COURSE_EDIT = 'courses_edit';
  public static PERMISSIONS_COURSE_EXCEL = 'courses_excel';
  public static PERMISSIONS_COURSE_REMOVE = 'courses_remove';
  public static PERMISSIONS_MY_COURSE_VIEW = 'mycourses_view';
  public static PERMISSIONS_MY_COURSE_DETAILS = 'mycourses_detail';
  public static PERMISSIONS_MY_COURSE_ADD = 'mycourses_add';
  public static PERMISSIONS_MY_COURSE_EDIT = 'mycourses_edit';
  public static PERMISSIONS_MY_COURSE_EXCEL = 'mycourses_excel';
  public static PERMISSIONS_MY_COURSE_REMOVE = 'mycourses_remove';
  public static PERMISSIONS_STUDENT_CLASSES_ADD = 'master_student_class_add';
  public static PERMISSIONS_STUDENT_CLASSES_VIEW = 'master_student_class_view';
  public static PERMISSIONS_STUDENT_CLASSES_EDIT = 'master_student_class_edit';
  public static PERMISSIONS_STUDENT_CLASSES_REMOVE =
    'master_student_class_remove';
}
