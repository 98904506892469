import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AuthenticationComponent } from './component/authentication/authentication.component';
import { FooterComponent } from './component/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from './services/AuthGuard/auth-guard.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HomeComponent } from './component/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { DataManagerService } from './services/DataManager/data-manager.service';
import { DatePipe } from '@angular/common';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import {
  DxSchedulerModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { ApprovalDialogComponent } from './dialogs/approval-dialog/approval-dialog.component';
import { AddPurposeOfDonationDialogComponent } from './dialogs/add-purpose-of-donation-dialog/add-purpose-of-donation-dialog.component';
import { ViewPurposeOfDonationDialogComponent } from './dialogs/view-purpose-of-donation-dialog/view-purpose-of-donation-dialog.component';
import { AddExpenseCategoryDialogComponent } from './dialogs/add-expense-category-dialog/add-expense-category-dialog.component';
import { ViewExpenseCategoryDialogComponent } from './dialogs/view-expense-category-dialog/view-expense-category-dialog.component';
import { ViewExpenseAccountDialogComponent } from './dialogs/view-expense-account-dialog/view-expense-account-dialog.component';
import { AddExpenseAccountDialogComponent } from './dialogs/add-expense-account-dialog/add-expense-account-dialog.component';
import { LandingComponent } from './landing/landing.component';
import { ShcoolPaymentDialogComponent } from './dialogs/shcool-payment-dialog/shcool-payment-dialog.component';
import { AnonymousDonationComponent } from './anonymous-donation/anonymous-donation.component';
import { ViewUpcomingeventDialogComponent } from './dialogs/view-upcomingevent-dialog/view-upcomingevent-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FuneralPaymentDialogComponent } from './dialogs/funeral-payment-dialog/funeral-payment-dialog.component';
import { CourseRegisterDialogComponent } from './dialogs/course-register-dialog/course-register-dialog.component';
import { AnonymousEventRegisterComponent } from './anonymous-event-register/anonymous-event-register.component';
import { ImageShowerDialogComponent } from './dialogs/image-shower-dialog/image-shower-dialog.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { ManageSystemDiscountComponent } from './finance/manage-system-discount/manage-system-discount.component';
import { CreateCustomRecurrenceComponent } from './dialogs/custom-recurrence/create-custom-recurrence/create-custom-recurrence.component';
import { AddSystemDiscountComponent } from './finance/add-system-discount/add-system-discount.component';
import { ViewDiscountDialogComponent } from './dialogs/view-discount-dialog/view-discount-dialog.component';
import { PaymentSuccessPageComponent } from './payment-success-page/payment-success-page.component';
import { UnsubscribeComponent } from './finance/unsubscribe/unsubscribe.component';
import { CancelMessageComponent } from './finance/cancel-message/cancel-message.component';
import { CustomCheckout } from './custom-checkout/CustomCheckout.module';
import { ViewClassDialogComponent } from './view-class-dialog/view-class-dialog.component';
import { AddStudentClassComponent } from './add-student-class/add-student-class.component';
import { FuneralSettingsComponent } from './funeral/funeral-settings/funeral-settings.component';
import { PaymentReceiptsComponent } from './payment-receipts/payment-receipts.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    FooterComponent,
    routingComponents,
    HomeComponent,
    MessageDialogComponent,
    ApprovalDialogComponent,
    AddPurposeOfDonationDialogComponent,
    ViewPurposeOfDonationDialogComponent,
    AddExpenseCategoryDialogComponent,
    ViewExpenseCategoryDialogComponent,
    ViewExpenseAccountDialogComponent,
    AddExpenseAccountDialogComponent,
    LandingComponent,
    ShcoolPaymentDialogComponent,
    AnonymousDonationComponent,
    ViewUpcomingeventDialogComponent,
    ResetPasswordComponent,
    FuneralPaymentDialogComponent,
    CourseRegisterDialogComponent,
    AnonymousEventRegisterComponent,
    ImageShowerDialogComponent,
    AlertDialogComponent,
    ManageSystemDiscountComponent,
    CreateCustomRecurrenceComponent,
    AddSystemDiscountComponent,
    ViewDiscountDialogComponent,
    PaymentSuccessPageComponent,
    UnsubscribeComponent,
    CancelMessageComponent,
    ViewClassDialogComponent,
    AddStudentClassComponent,
    FuneralSettingsComponent,
    PaymentReceiptsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    DxSchedulerModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CustomCheckout,
  ],
  schemas: [],
  providers: [
    AuthGuardService,
    DataManagerService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'LOCALSTORAGE',
      useFactory: getLocalStorage,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getLocalStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}
