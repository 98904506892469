<div class="" *ngIf="viewUpcomingData">
<div class="w-100 {{dialogData.width}}">
    <div class="row">
        <div class="col-10 text-left p-0">
            <h1 class="m-0">
                {{viewUpcomingData.text | titlecase}}
            </h1>
            <span>
                <i class="fa fa-calendar" aria-hidden="true"></i>
                <small class="small pl-1">{{viewUpcomingData.repeat == 2 ? (viewUpcomingData.startDate + ' '+
                    (viewUpcomingData.start_time ? viewUpcomingData.start_time : '') + ' to ' + viewUpcomingData.endDate + ' ' + (viewUpcomingData.end_time ? viewUpcomingData.end_time : '') +
                    " (" + viewUpcomingData.recurring + ')' ) :
                    (viewUpcomingData.startDate + ' ' + (viewUpcomingData.start_time ? viewUpcomingData.start_time : '') + ' to ' + viewUpcomingData.endDate +
                    ' ' + (viewUpcomingData.end_time ? viewUpcomingData.end_time : ''))}}</small>
            </span>
        </div>
        <div class="col-2 text-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                    aria-hidden="true">×</span></button>
        </div>
    </div>

</div>
<div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mt-2 px-0">
    <mat-card class="view-page mat-card mat-focus-indicator p-0">
        <div class="form-field-full">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 p-0">
                        <p>{{('Events & Timings - ' + viewUpcomingData.text | titlecase) + ' that begins on ' +
                            (viewUpcomingData.repeat == 2 ?
                            ((viewUpcomingData.start_time ? viewUpcomingData.startDate : '') + ' '+
                            (viewUpcomingData.start_time ? viewUpcomingData.start_time : '') + ' to ' + viewUpcomingData.endDate + ' ' +
                            (viewUpcomingData.end_time ? viewUpcomingData.end_time : '') +
                            " (" + viewUpcomingData.recurring + ')' ) :
                            (viewUpcomingData.startDate + ' ' + (viewUpcomingData.start_time ? viewUpcomingData.start_time : '')+ ' to ' +
                            viewUpcomingData.endDate +
                            ' ' + (viewUpcomingData.end_time ? viewUpcomingData.end_time : '')))}}</p>
                        <p>{{'Description - ' + (viewUpcomingData.description | titlecase)}}</p>
                        <p>{{'Venue - ' + (viewUpcomingData.venue | titlecase)}}</p>
                        <p>{{'Organizer - ' + (viewUpcomingData.organizer | titlecase)}}</p>
                        <div class="d-flex">
                            Google Link -  
                            <a *ngIf="viewUpcomingData.google_link.startsWith('http')" href="{{viewUpcomingData.google_link}}" target="_blank" class="ml-1 mb-2">Click here</a>
                            <p *ngIf="!viewUpcomingData.google_link.startsWith('http')" class="ml-1">{{(viewUpcomingData.google_link)}}</p>
                        </div>
                        
                        <h4 class="">Event Contact Info:</h4>
                        <p>{{'Phone: ' + (viewUpcomingData.phone) + ', Email: ' + (viewUpcomingData.email)}}</p>
                        <h4 class="">Event Cost Info:</h4>
                        <p>{{viewUpcomingData.type == 1 ? ('Free: ' + '$0.00 CAD') : 'Cost : '+ (viewUpcomingData.cost + ' CAD')}}
                        </p>
                        <h4 class="">Event Capacity:</h4>
                        <p>{{ + (viewUpcomingData.age_type == 1) ? + viewUpcomingData.capacity + ' members only. And allowed age between ' + (viewUpcomingData.min_age +'-' + viewUpcomingData.max_age)+'.':
                            viewUpcomingData.capacity + ' members only.'}}</p>
                        <h4 class="">Event Registration:</h4>
                        <p *ngIf="viewUpcomingData.reg_req == 1">For registration: <a  href="javascript:void(0)"
                                (click)="goToEventReg()" *ngIf="viewUpcomingData.registration_capacity_reached === '0'">{{viewUpcomingData.text | titlecase}}</a>
                                <span class="registration_limit" *ngIf="viewUpcomingData.registration_capacity_reached === '1'">Registration capacity is reached.</span>
                        </p>
                        <p *ngIf="viewUpcomingData.reg_req == 0">Registration Not Required.
                    </p>
                    </div>
                    <div class="col-12 p-0 view-content">

                    </div>
                </div>
            </div>
        </div>
    </mat-card>

</div>
</div>
